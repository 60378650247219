import { Inject, Injectable } from '@angular/core';
import {
  CasePartySnapshot,
  CasePartyViewModel,
  CaseRequestSnapshot,
  CaseRequestViewModel,
  CombinedFilingData,
  Document,
  DocumentInfo,
  DocumentRenderingInfo,
  DocumentReviewRequestStatus,
  DocumentReviewRequestSummary,
  FeeItem,
  Filing,
  FilingMode,
  FilingProfile,
  FsxFilingApiService,
  IFilingApiService,
  RenderingNameEnum,
  RenderingStatus,
  RequestDocumentViewModel,
  RequestParticipantSnapshot,
  RequestParticipantViewModel,
  RequestType,
} from '@fsx/fsx-shared';
import { catchError, EMPTY, take, tap } from 'rxjs';
import { RequestTypesJoined } from '../status/status.component';
import { PartyAndParticipant } from '../../filing-editor/services/party-and-participant-data.service';

export interface TransactionDetails {
  authorizer: string;
  submittedBy: string;
  clientMatterNumber: string;
  court: string;
  caseClass: string;
  totalFees: {
    currencyCode: string;
    total: number | string;
  };
  assignedJudge: string;
  caseType: string;
  modeType: string;
  mode: string;
  type: string;
}
export interface TransactionDocument {
  id: string;
  documentNumber: string | number;
  documentName: string;
  documentCategory: string;
  access: string;
  pdfSize: string;
  pdfPages: number;
  status: DocumentReviewRequestStatus | null;
  statusReason: string | null | undefined;
  url: string;
  renderingName: string;
  availableRenderings: {
    clerkStamped: boolean;
    converted: boolean;
    original: boolean;
  };
  fileName: string;
  entries: string[];
  statutoryFees: TransactionFee[];
  reviewRequestName: string | null | undefined;
}
export interface TransactionDocumentServed {
  documentNumber: string | number;
  documentName: string;
  documentType: string;
  serviceDate: string;
  serviceTime: string;
  serviceContact: string;
  serviceEmail: string;
  status: string;
}
export interface TransactionPartyRepresentation {
  name: string;
  role: string | null | undefined;
  firm: string;
}
export interface TransactionParty {
  name: string;
  type: string | undefined;
  representation: TransactionPartyRepresentation[] | undefined;
}
export interface TransactionFee {
  total: number;
  currencyCode: string;
  caption: string;
}
export interface TransactionInfo {
  filingId: string;
  id: string | null | undefined;
  rejectionReason: string | null | undefined;
  dateReceived: string | null | undefined;
  dateSubmitted: string | null | undefined;
  dateUpdated: string | null | undefined;
  caseNumber: string | null | undefined;
  caseName: string | null | undefined;
  details: TransactionDetails;
  documents: TransactionDocument[];
  documentsServed: TransactionDocumentServed[];
  parties: TransactionParty[];
  fees: TransactionFee[];
  noteToClerk: string;
  isEstimate: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TransactionInfoService {
  constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService
  ) {}

  public getTransactionInfo(
    cfd: CombinedFilingData,
    requestDocuments: RequestDocumentViewModel[]
  ): TransactionInfo {
    const transactionInfo: TransactionInfo = {
      filingId: cfd.filing.id,
      id: '-',
      rejectionReason: null,
      dateReceived: '-',
      dateSubmitted: '-',
      dateUpdated: '-',
      caseNumber: '-',
      caseName: '-',
      details: {
        authorizer: '-',
        submittedBy: '-',
        clientMatterNumber: '-',
        court: '-',
        caseClass: '-',
        totalFees: {
          currencyCode: 'USD',
          total: 'loading',
        },
        assignedJudge: '-',
        caseType: '-',
        modeType: '-',
        mode: '-',
        type: '-',
      },
      documents: [],
      documentsServed: [],
      parties: [],
      fees: [],
      isEstimate: true,
      noteToClerk: cfd.caseRequest.noteToClerk
        ? cfd.caseRequest.noteToClerk
        : '-',
    };
    this._setTransactionDetails(cfd.filing, cfd.filingProfile, transactionInfo);
    this._setTransactionDocuments(
      cfd.filing,
      requestDocuments,
      transactionInfo
    );
    this._setTransactionDocumentsServed(cfd.caseRequest, transactionInfo);
    this._setTransactionParties(cfd, transactionInfo);
    this._setTransactionFees(cfd, transactionInfo);
    return transactionInfo;
  }

  /**
   * Gets transaction info from a CaseRequestSnapshot instead of CFD
   * @param caseRequestSnapshot
   */
  public getTransactionInfoSnapshotVersion(
    caseRequestSnapshot: CaseRequestSnapshot
  ): TransactionInfo {
    const transactionInfo: TransactionInfo = {
      filingId: caseRequestSnapshot.filing.id,
      id: '-',
      rejectionReason: null,
      dateReceived: '-',
      dateSubmitted: '-',
      dateUpdated: '-',
      caseNumber: '-',
      caseName: '-',
      details: {
        authorizer: '-',
        submittedBy: '-',
        clientMatterNumber: '-',
        court: '-',
        caseClass: '-',
        totalFees: {
          currencyCode: 'USD',
          total: 'loading',
        },
        assignedJudge: '-',
        caseType: '-',
        modeType: '-',
        mode: '-',
        type: '-',
      },
      documents: [],
      documentsServed: [],
      parties: [],
      fees: [],
      isEstimate: true,
      noteToClerk: caseRequestSnapshot.noteToClerk ?? '-',
    };
    // Set Details
    this._setTransactionDetailsFromSnapshot(
      caseRequestSnapshot,
      transactionInfo
    );
    // Set documents
    this._setTransactionDocumentsFromSnapshot(
      caseRequestSnapshot,
      transactionInfo
    );
    // ToDo: Set documents served
    // Set parties
    this._setTransactionPartiesFromSnapshot(
      caseRequestSnapshot,
      transactionInfo
    );
    // Set fees
    this._setTransactionFeesFromSnapshot(caseRequestSnapshot, transactionInfo);
    // return info
    return transactionInfo;
  }

  /**
   * Sets transaction details from filing and profile
   * @param filing
   * @param filingProfile
   * @param transactionInfo
   * @private
   */
  private _setTransactionDetails(
    filing: Filing,
    filingProfile: FilingProfile,
    transactionInfo: TransactionInfo
  ) {
    if (!!filing && !!filingProfile) {
      // Set transaction Info
      transactionInfo.id = filing.courtCases[0].transactionEfmKey ?? '-';
      transactionInfo.rejectionReason =
        filing.courtCases[0].statusReason ?? null;
      transactionInfo.caseNumber = filing.courtCases[0].caption ?? '-';
      transactionInfo.caseName = filing.courtCases[0].title ?? '-';
      transactionInfo.dateReceived =
        filing.courtCases.length && !!filing.courtCases[0].efmDateReceived
          ? filing.courtCases[0].efmDateReceived
          : '-'; // ToDo: THIS DOESNT SUPPORT MULT-ICASE!.
      transactionInfo.dateSubmitted = filing.submittedAt;
      transactionInfo.dateUpdated = filing.modifiedAt;
      // Set Transaction Details
      transactionInfo.details.authorizer = filing.authorizer
        ? filing.authorizer.caption
        : '-'; // ToDo: Available but authorizer set to null in DEV accounts;
      transactionInfo.details.submittedBy = filing.filer.caption;
      transactionInfo.details.clientMatterNumber =
        filing.courtCases[0].clientMatterKey; // ToDo: THIS DOESNT SUPPORT MULT-ICASE!.
      transactionInfo.details.court = filing.courtSummary.caption;
      transactionInfo.details.assignedJudge = '-';
      transactionInfo.details.modeType = `${this.getMode(
        filing.mode
      )} / ${this.getType(filing.requestTypes)}`;
      transactionInfo.details.mode = this.getMode(filing.mode);
      transactionInfo.details.type = this.getType(filing.requestTypes);
      transactionInfo.details.caseClass = '-';
      transactionInfo.details.caseType = '-';

      const classification = filingProfile.classification;
      if (classification.length) {
        transactionInfo.details.caseClass = classification
          .map((c) => c.caption)
          .join(' - ');
        const caseType = classification.find((c) => c.label === 'Case Type');
        if (!!caseType) {
          transactionInfo.details.caseType = caseType.caption;
        }
      }
    }
  }

  /**
   * Sets transaction details from a CaseRequestSnapshot instead of CFD
   * @param caseRequestSnapshot
   * @param transactionInfo
   * @private
   */
  private _setTransactionDetailsFromSnapshot(
    caseRequestSnapshot: CaseRequestSnapshot,
    transactionInfo: TransactionInfo
  ): void {
    // ToDo: THIS DOESNT SUPPORT MULT-ICASE!.
    const filing = caseRequestSnapshot.filing;
    const firstCase = caseRequestSnapshot.filing.courtCases[0];
    // Set transaction info
    transactionInfo.id = firstCase.transactionEfmKey ?? '-';
    transactionInfo.caseNumber = firstCase.caption ?? '-';
    transactionInfo.caseName = firstCase.title ?? '-';
    transactionInfo.dateReceived = firstCase.efmDateReceived ?? '-';
    transactionInfo.dateSubmitted = filing.submittedAt;
    transactionInfo.dateUpdated = filing.modifiedAt;
    // Set details
    transactionInfo.details.authorizer = filing.authorizer
      ? filing.authorizer.caption
      : '-'; // ToDo: Available but authorizer set to null in DEV accounts;
    transactionInfo.details.submittedBy = filing.filer.caption;
    transactionInfo.details.clientMatterNumber =
      filing.courtCases[0].clientMatterKey; // ToDo: THIS DOESNT SUPPORT MULT-ICASE!.
    transactionInfo.details.court = filing.courtSummary.caption;
    transactionInfo.details.assignedJudge = '-';
    transactionInfo.details.modeType = `${this.getMode(
      filing.mode
    )} / ${this.getType(filing.requestTypes)}`;
    transactionInfo.details.mode = this.getMode(filing.mode);
    transactionInfo.details.type = this.getType(filing.requestTypes);
    transactionInfo.details.caseClass = '-';
    transactionInfo.details.caseType = '-';
    const classification =
      caseRequestSnapshot.filing.profileSummary?.classification;
    if (!!classification && classification.length) {
      transactionInfo.details.caseClass = classification
        .map((c) => c.caption)
        .join(' - ');
      const caseType = classification.find((c) => c.label === 'Case Type');
      if (!!caseType) {
        transactionInfo.details.caseType = caseType.caption;
      }
    }
  }

  /**
   * Sets documents info for transaction
   * @param filing
   * @param requestDocuments
   * @param transactionInfo
   */
  private _setTransactionDocuments(
    filing: Filing,
    requestDocuments: RequestDocumentViewModel[],
    transactionInfo: TransactionInfo
  ) {
    const filingId = filing.id;
    const courtCase = filing.courtCases[0]; // ToDo: Doesn't support multi-case.

    this.filingApiService
      .getDocumentInfos(filingId)
      .pipe(
        take(1),
        tap((documentInfos: DocumentInfo[]) => {
          documentInfos.forEach((documentInfo: DocumentInfo) => {
            const requestDocument: RequestDocumentViewModel =
              requestDocuments.find(
                (rd) => rd.id === documentInfo.id
              ) as RequestDocumentViewModel;
            const documentReviewRequestSummary: DocumentReviewRequestSummary =
              courtCase.documentReviewRequests.find(
                (drr) => drr.documentId === documentInfo.id
              ) as DocumentReviewRequestSummary;
            if (!!requestDocument) {
              // Get Rendering
              const rendering: DocumentRenderingInfo =
                this.getDocumentRenderingInfo(documentInfo);
              const transactionDocument: TransactionDocument =
                {} as TransactionDocument;
              transactionDocument.id = requestDocument.id as string;
              transactionDocument.documentName = requestDocument.title;
              transactionDocument.documentCategory = requestDocument.category
                ?.caption as string;
              transactionDocument.renderingName = rendering.name;
              transactionDocument.fileName = requestDocument.fileName;
              transactionDocument.access =
                (requestDocument.accessCategoryName as string) ?? '-';
              transactionDocument.pdfPages = rendering.pageCount;
              transactionDocument.pdfSize = this.getSize(rendering.size);
              transactionDocument.status = !!documentReviewRequestSummary
                ? documentReviewRequestSummary.reviewStatus
                : DocumentReviewRequestStatus.Unknown;
              transactionDocument.statusReason = !!documentReviewRequestSummary
                ? documentReviewRequestSummary.statusReason
                : '-';
              transactionDocument.statutoryFees = [];
              transactionDocument.availableRenderings = {
                clerkStamped: !!documentInfo.renderings?.find(
                  (rendering) =>
                    rendering.name === RenderingNameEnum.ClerkStamped &&
                    rendering.status === RenderingStatus.Ready
                ),
                converted: !!documentInfo.renderings?.find(
                  (rendering) =>
                    rendering.name === RenderingNameEnum.ConvertedPdf &&
                    rendering.status === RenderingStatus.Ready
                ),
                original: !!documentInfo.renderings?.find(
                  (rendering) =>
                    rendering.name === RenderingNameEnum.Original &&
                    rendering.status === RenderingStatus.Ready
                ),
              };
              transactionDocument.entries = [];
              transactionDocument.reviewRequestName =
                documentReviewRequestSummary?.name || null;
              transactionInfo.documents.push(transactionDocument);
            }
          });
        }),
        catchError((err) => {
          console.error('Error getting documents:', err);
          return EMPTY;
        })
      )
      .subscribe();
  }

  /**
   * Sets documents info for transaction from a CaseRequestSnapshot instead of CFD
   * @param caseRequestSnapshot
   * @param transactionInfo
   * @private
   */
  private _setTransactionDocumentsFromSnapshot(
    caseRequestSnapshot: CaseRequestSnapshot,
    transactionInfo: TransactionInfo
  ): void {
    caseRequestSnapshot.documents;
    const documentSnapshots = caseRequestSnapshot.documents;
    documentSnapshots?.forEach((documentSnapshot) => {
      const document: Document = documentSnapshot.document as Document;
      const rendering = this.getDocumentRenderingInfo(document);
      const transactionDocument: TransactionDocument =
        {} as TransactionDocument;
      transactionDocument.id = documentSnapshot.id as string;
      transactionDocument.documentName = documentSnapshot.title;
      transactionDocument.documentCategory = documentSnapshot.category
        ?.caption as string;
      transactionDocument.renderingName = rendering.name;
      transactionDocument.fileName = documentSnapshot.fileName;
      transactionDocument.access = documentSnapshot.accessCategory?.name ?? '-';
      transactionDocument.pdfPages = rendering.pageCount;
      transactionDocument.pdfSize = this.getSize(rendering.size);
      transactionDocument.status = null;
      transactionDocument.statusReason = null;
      transactionDocument.statutoryFees = [];
      transactionDocument.availableRenderings = {
        clerkStamped: !!document.renderings?.find(
          (rendering) => rendering.name === RenderingNameEnum.ClerkStamped
        ),
        converted: !!document.renderings?.find(
          (rendering) => rendering.name === RenderingNameEnum.ConvertedPdf
        ),
        original: !!document.renderings?.find(
          (rendering) => rendering.name === RenderingNameEnum.Original
        ),
      };
      transactionDocument.entries = [];
      transactionInfo.documents.push(transactionDocument);
    });
  }

  /**
   * Sets documents served for transaction. ToDo: To be implemnted correctly still.
   * @param caseRequest
   * @param transactionInfo
   * @private
   */
  private _setTransactionDocumentsServed(
    caseRequest: CaseRequestViewModel,
    transactionInfo: TransactionInfo
  ) {
    if (!!caseRequest) {
      caseRequest.documents?.forEach(() => {
        const transactionDocument: TransactionDocumentServed = {
          documentNumber: '[Number]',
          documentName: '[Name]',
          documentType: '[Type]',
          serviceDate: '[Service Date]',
          serviceTime: '[Service Time]',
          serviceContact: '[Service Contact]',
          serviceEmail: '[Service Email]',
          status: '[Service Status]',
        };
        transactionInfo.documentsServed.push(transactionDocument);
      });
    }
  }

  /**
   * Sets parties info on transaction.
   * @param cfd
   * @param transactionInfo
   * @private
   */
  private _setTransactionParties(
    cfd: CombinedFilingData,
    transactionInfo: TransactionInfo
  ) {
    const parties: CasePartyViewModel[] = cfd?.caseRequest?.parties || [];
    const participants: RequestParticipantViewModel[] =
      cfd?.caseRequest?.participants || [];
    const partiesAndParticipant = parties.map(
      (party: CasePartyViewModel, index: number) => {
        const participant: RequestParticipantViewModel = participants.find(
          (p) => p.name === party.participantName
        )!;
        const partyAndParticipant: PartyAndParticipant = {
          party,
          participant,
          partyIndex: index,
        };
        return partyAndParticipant;
      }
    );

    partiesAndParticipant.forEach((partyAndParticipant) => {
      const transactionParty: TransactionParty = {
        name: partyAndParticipant.party.caption,
        type: partyAndParticipant.party.participantCategory?.caption,
        representation: partyAndParticipant.party.representation?.map((rep) => {
          const transactionRepresentation: TransactionPartyRepresentation = {
            name: rep.caption,
            role: rep.participantCategory?.caption,
            firm: '-',
          };
          const repParticipant = participants.find(
            (participant) => participant.name === rep.participantName
          );
          transactionRepresentation.firm = !!repParticipant
            ? repParticipant.organization?.caption ?? '-'
            : '-';
          return transactionRepresentation;
        }),
      };
      transactionInfo.parties.push(transactionParty);
    });
  }

  /**
   * Sets parties info on transaction from a CaseRequestSnapshot instead of CFD
   * @param caseRequestSnapshot
   * @param transactionInfo
   * @private
   */
  private _setTransactionPartiesFromSnapshot(
    caseRequestSnapshot: CaseRequestSnapshot,
    transactionInfo: TransactionInfo
  ): void {
    const parties = caseRequestSnapshot.parties ?? [];
    const participants = caseRequestSnapshot.participants ?? [];
    const partiesAndParticipants = parties.map((party, index: number) => {
      const participant = participants.find(
        (p) => p.name === party.participantName
      )!;
      const partyAndParticipant: {
        party: CasePartySnapshot;
        participant: RequestParticipantSnapshot;
        partyIndex: number;
      } = {
        party,
        participant,
        partyIndex: index,
      };
      return partyAndParticipant;
    });
    partiesAndParticipants.forEach((partyAndParticipant) => {
      const transactionParty: TransactionParty = {
        name: partyAndParticipant.party.caption,
        type: partyAndParticipant.party.participantCategory?.caption,
        representation: partyAndParticipant.party.representation?.map((rep) => {
          const transactionRepresentation: TransactionPartyRepresentation = {
            name: rep.caption,
            role: rep.participantCategory?.caption,
            firm: '-',
          };
          const repParticipant = participants.find(
            (participant) => participant.name === rep.participantName
          );
          transactionRepresentation.firm = !!repParticipant
            ? repParticipant.organization?.caption ?? '-'
            : '-';
          return transactionRepresentation;
        }),
      };
      transactionInfo.parties.push(transactionParty);
    });
  }

  /**
   *
   * @param cfd
   * @param transactionInfo
   * @private
   */
  private _setTransactionFees(
    cfd: CombinedFilingData,
    transactionInfo: TransactionInfo
  ) {
    // ToDo: This is a temp solution. Will change this when I get back.
    const fees = cfd.filing.courtCases[0].fees;
    transactionInfo.details.totalFees.total = fees?.total.amount ?? '-';
    transactionInfo.details.totalFees.currencyCode =
      fees?.total.currencyKey?.toUpperCase() ?? '-';
    transactionInfo.isEstimate = fees?.isEstimate ?? true;
    // Set itemized fees
    const transactionFees: TransactionFee[] = [];
    fees?.items.forEach((feeItem: FeeItem) => {
      const fee: TransactionFee = {} as TransactionFee;
      fee.caption = feeItem.caption;
      fee.currencyCode = feeItem.amount.currencyKey?.toUpperCase() as string;
      fee.total = feeItem.amount.amount;
      transactionFees.push(fee);
    });
    transactionInfo.fees = transactionFees;
  }

  /**
   * Helper method to get the best available rendering for a document.
   * @param documentInfo
   * @private
   */
  public getDocumentRenderingInfo(
    documentInfo: DocumentInfo | Document
  ): DocumentRenderingInfo {
    if (!!documentInfo.renderings) {
      if (
        !!documentInfo.renderings.find(
          (di) => di.name === RenderingNameEnum.ClerkStamped
        )
      ) {
        return documentInfo.renderings.find(
          (di) => di.name === RenderingNameEnum.ClerkStamped
        ) as DocumentRenderingInfo;
      } else if (
        !!documentInfo.renderings.find(
          (di) => di.name === RenderingNameEnum.ConvertedPdf
        )
      ) {
        return documentInfo.renderings.find(
          (di) => di.name === RenderingNameEnum.ConvertedPdf
        ) as DocumentRenderingInfo;
      } else if (
        !!documentInfo.renderings.find(
          (di) => di.name === RenderingNameEnum.Original
        )
      ) {
        return documentInfo.renderings.find(
          (di) => di.name === RenderingNameEnum.Original
        ) as DocumentRenderingInfo;
      }
    }
    return {} as DocumentRenderingInfo;
  }

  /**
   * Sets transaction fees from a CaseRequestSnapshot instead of CFD.
   * No longer makes calls to JTI for fees.
   * @param caseRequestSnapshot
   * @param transactionInfo
   * @private
   */
  private _setTransactionFeesFromSnapshot(
    caseRequestSnapshot: CaseRequestSnapshot,
    transactionInfo: TransactionInfo
  ): void {
    const fees = caseRequestSnapshot.filing.courtCases[0].fees;
    transactionInfo.details.totalFees.total = fees?.total.amount ?? '-';
    transactionInfo.details.totalFees.currencyCode =
      fees?.total.currencyKey?.toUpperCase() ?? '-';
    transactionInfo.isEstimate = fees?.isEstimate ?? true;
    // Set itemized fees
    const transactionFees: TransactionFee[] = [];
    fees?.items.forEach((feeItem: FeeItem) => {
      const fee: TransactionFee = {} as TransactionFee;
      fee.caption = feeItem.caption;
      fee.currencyCode = feeItem.amount.currencyKey?.toUpperCase() as string;
      fee.total = feeItem.amount.amount;
      transactionFees.push(fee);
    });
    transactionInfo.fees = transactionFees;
  }

  /**
   * Helper method to get the mode as a string
   * @param mode
   */
  public getMode(mode: FilingMode): string {
    switch (mode) {
      case FilingMode.Appellate:
        return 'Appellate';
      case FilingMode.OriginalPetition:
        return 'OPF';
      case FilingMode.Subsequent:
        return 'SubF';
      case FilingMode.None:
        return 'None';
      default:
        return '-';
    }
  }

  /**
   * Helper method to get the fling type.
   * @param types
   */
  public getType(types: RequestType[]): string {
    if (
      types.includes(RequestType.Case) &&
      types.includes(RequestType.Service)
    ) {
      return RequestTypesJoined.FileAndServe;
    } else if (
      types.includes(RequestType.Case) &&
      !types.includes(RequestType.Service)
    ) {
      return RequestTypesJoined.FileOnly;
    } else if (
      !types.includes(RequestType.Case) &&
      types.includes(RequestType.Service)
    ) {
      return RequestTypesJoined.Service;
    } else {
      return RequestTypesJoined.None;
    }
  }

  /**
   * Helper method to get the size of a file.
   * @param bytes
   */
  public getSize(bytes: number): string {
    if (bytes > 1000) {
      let kbs = Math.floor(bytes / 1000);
      return `${kbs} KB`;
    }
    return `${bytes} B`;
  }
}
