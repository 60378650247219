import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxAllowedValidator(maxAllowed: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      (control.value &&
        control.value.length &&
        control.value.length <= maxAllowed) ||
      control.value === null ||
      control.value === undefined ||
      typeof control.value === 'string'
    ) {
      return null;
    }
    return {
      invalidMaxAllowed: `You can only select ${maxAllowed} ${
        maxAllowed === 1 ? 'option' : 'options'
      }`,
    };
  };
}
