import { Pipe, PipeTransform } from '@angular/core';
import { FilingMode } from '@fsx/fsx-shared';

@Pipe({
  name: 'filingModeDisplayName',
})
export class FilingModeDisplayNamePipe implements PipeTransform {
  transform(mode: FilingMode): string {
    if (!!mode) {
      switch (mode) {
        case FilingMode.Appellate:
          return 'Appellate';
        case FilingMode.Subsequent:
          return 'Subf';
        case FilingMode.OriginalPetition:
          return 'OPF';
        case FilingMode.None:
          return 'None';
      }
    }
    return mode;
  }
}
