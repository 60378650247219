import { Component, Inject } from '@angular/core';
import { FilingSubTabClickedParams } from '../filing-sub-tab-item/filing-sub-tab-item.component';
import { FilingSubTabItem } from '@fsx/fsx-shared';
import { Observable } from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from './filing-sub-tabs.service';

@Component({
  selector: 'fsx-filing-sub-tabs-container',
  templateUrl: './filing-sub-tabs-container.component.html',
  styleUrls: ['./filing-sub-tabs-container.component.scss'],
})
export class FilingSubTabsContainerComponent {
  filingSubTabItems$: Observable<FilingSubTabItem[]> =
    this.filingSubTabsService.filingSubTabItems$;

  constructor(
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService
  ) {}

  filingSubTabClickedEventHandler(params: FilingSubTabClickedParams) {
    this.filingSubTabsService.activateFilingSubTabItem(
      params.filingSubTabItem.name
    );
  }
}
