import { FilingUpdate } from '../dot-net-api-types';

export interface FilingEndPoints {
  bookmark: (filingId: string) => string;
  filings: () => string;
  fees: (filingId: string) => string;
  draftFilingHeader: (filingId: string) => string;
  caseRequest: (filingId: string) => string;
  caseRequestSnapshot: (filingId: string, snapshotKey: string) => string;
  document: (filingId: string, documentId: string) => string;
  documents: (filingId: string) => string;
  documentRendering: (
    filingId: string,
    documentId: string,
    renderingName: string
  ) => string;
  documentHistory: (
    filingId: string,
    documentId: string,
    reviewRequestName: string
  ) => string;
  submit: (filingId: string) => string;
  updateFiling: (filingId: string, filingUpdate: FilingUpdate) => string;
  deleteFiling: (filingId: string) => string;
  search: (filingId: string, searchName: string) => string;
  parties: (filingId: string, efmKey: string) => string;
  party: (filingId: string, efmKey: string, partyKey: string) => string;
  participant: (
    filingId: string,
    efmKey: string,
    participantName: string
  ) => string;
  participants: (filingId: string, efmKey: string) => string;
}

export const filingEndPoints = (versionedUrl: string): FilingEndPoints => {
  const baseUrl = `${versionedUrl}/filings`;

  return {
    bookmark: (filingId: string) => `${baseUrl}/${filingId}/bookmark`,
    filings: () => baseUrl,
    fees: (filingId: string) => `${baseUrl}/${filingId}/fees`,
    draftFilingHeader: (filingId: string) => `${baseUrl}/${filingId}`,
    caseRequest: (filingId: string) => `${baseUrl}/${filingId}/request`,
    caseRequestSnapshot: (filingId, snapshotKey) =>
      `${baseUrl}/${filingId}/request/snapshot/${snapshotKey}`,
    document: (filingId: string, documentId: string) =>
      `${baseUrl}/${filingId}/documents/${documentId}`,
    documents: (filingId: string) => `${baseUrl}/${filingId}/documents`,
    documentRendering: (
      filingId: string,
      documentId: string,
      renderingName: string
    ) => `${baseUrl}/${filingId}/documents/${documentId}/${renderingName}`,
    documentHistory: (
      filingId: string,
      documentId: string,
      reviewRequestName: string
    ) =>
      `${baseUrl}/${filingId}/documents/${documentId}/reviews/${reviewRequestName}/history`,
    submit: (filingId: string) => `${baseUrl}/${filingId}/request/submit`,
    updateFiling: (filingId: string, filingUpdate: FilingUpdate) =>
      `${baseUrl}/${filingId}`,
    deleteFiling: (filingId: string) => `${baseUrl}/${filingId}`,
    search: (filingId, searchName) =>
      `${baseUrl}/${filingId}/search/${searchName}/query`,
    parties: (filingId: string, efmKey: string) =>
      `${baseUrl}/${filingId}/cases/${efmKey}/parties/query`,
    party: (filingId: string, efmKey: string, partyKey: string) =>
      `${baseUrl}/${filingId}/cases/${efmKey}/parties/${partyKey}`,
    participant: (filingId: string, efmKey: string, participantName: string) =>
      `${baseUrl}/${filingId}/cases/${efmKey}/participants/${participantName}`,
    participants: (filingId: string, efmKey: string) =>
      `${baseUrl}/${filingId}/cases/${efmKey}/participants/query`,
  };
};
