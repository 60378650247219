import { Injectable, Inject, InjectionToken } from '@angular/core';
import {
  Direction,
  FsxApiFilter,
  FsxApiSort,
  Filing,
  IFilingApiService,
  FsxApiFilterSourceControl,
  FsxFilingApiService,
  FilingGridResult,
} from '@fsx/fsx-shared';
import {
  Observable,
  switchMap,
  Subject,
  BehaviorSubject,
  withLatestFrom,
  map,
  merge,
  tap,
  shareReplay,
  filter,
  delay,
} from 'rxjs';
import { TransactionsEventService } from './transactions-event.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IFilingsService to use at runtime.
 */
export const FsxFilingsService = new InjectionToken<IFilingsService>(
  'FsxFilingsService'
);

/**
 * An enum of the columns the user can filter by.
 */
export enum FilterColumnEnum {
  CreatedAt = 'CreatedAt',
  Status = 'Status',
  Search = 'Search',
  Bookmarked = 'Bookmarked',
}

/**
 * The data strucure in which we gather the properties needed
 * to apply a filter.
 */
export interface FilterAndSortParams {
  filterArray: FsxApiFilter[];
  sortArray: FsxApiSort[];
  clearList: boolean;
}

/**
 * The data structure in which we set the properties needed
 * to apply a paged transactions query
 */
export interface SkipAndLimit {
  skip: number;
  limit: number;
}

/**
 * The data structure to store the paging data
 * i.e. the count and total of transactions
 */
export interface PagingData {
  count: number;
  total: number;
}

/**
 * The output data structure, which we send back to calling components.
 */
export interface TransactionsData {
  transactions: Filing[];
  isLoading: boolean;
  pagingData: PagingData;
}

/**
 * The data structre consisting of the properties needed to
 * initiate a new filter (just clearList, currently). We go on
 * to convert this into FilterAndSortParams in the streams.
 */
export interface ApplyFilterParams {
  clearList: boolean;
}

/**
 * A blueprint of a ui service, which stores the transaction list data and
 * allows filters and sorts to be applied against it.
 */
export interface IFilingsService {
  /**
   * The transaction data exposed as an Observable.
   */
  transactionsData$: Observable<TransactionsData>;

  /**
   * A method to apply any previosuly set filter.
   */
  applyFilter(): void;

  /**
   * A method to allow filters to be added to the locally stored filters.
   *
   * @param filter The filter to be added
   */
  addFilter(filter: FsxApiFilter): void;

  /**
   * A method to allow for the conditional removal of previously added filters
   *
   * @param column The column we want to remove existing filters for.
   * @param sourceControl The source control we want to remove existing filters for.
   */
  removeFilters(column: string, sourceControl: string): void;

  /**
   * A method to allow removal of all filters originating from a given source control.
   *
   * @param sourceControl The sourceControl that we want to remove filters for.
   */
  removeFiltersForSourceControl(sourceControl: FsxApiFilterSourceControl): void;

  /**
   * A method to allow clearing of all non-default filters.
   * (default filters cannot be cleared)
   */
  resetFilter(): void;

  /**
   * A method to allow sorting by a specified column.
   *
   * @param column The column to sort on
   */
  addSort(column: FilterColumnEnum): void;

  /**
   * A method to trigger retrieval of the next page of data.
   */
  nextPage(): void;
}

/**
 * A concrete implementation of a ui service, which stores the transaction list
 * data and allows filters and sorts to be applied against it.
 */
@Injectable()
export class FilingsService implements IFilingsService {
  /**
   * The default sort to apply to the transaction list data.
   */
  private defaultSort: FsxApiSort = {
    column: FilterColumnEnum.CreatedAt,
    direction: Direction.Descending,
  };

  /**
   * The initial paging data to retrieve the first page of transactions data.
   */
  private defaultSkipAndLimit: SkipAndLimit = {
    skip: 0,
    limit: 40,
  };

  /**
   * The user-specified sorts to pass to subsequent api calls to sort the transactions.
   */
  private sortArray$$ = new BehaviorSubject<FsxApiSort[]>([this.defaultSort]);

  /**
   * The user-specified filters to pass to subsequent api calls to filter the transactions.
   */
  private filterArray$$ = new BehaviorSubject<FsxApiFilter[]>([]);

  /**
   * The paging data to pass to subsequent api calls to tell it which page of data to load for.
   */
  private skipAndLimit$$ = new BehaviorSubject<SkipAndLimit>(
    this.defaultSkipAndLimit
  );

  /**
   * A local copy of the last emitted transactions data.
   */
  private transactionsCache$$ = new BehaviorSubject<Filing[]>([]);

  /**
   * A subject to allow filters to be applied as part of an Observable stream.
   */
  private applyFilterAction$$ = new Subject<void>();

  /**
   * A subject to allow a new page of data to be loaded as part of an Observable stream.
   */
  private nextPageAction$$ = new Subject<void>();

  /**
   * A stream to clear the list (whilst loading) and trigger the retrieval of new transactions data.
   *
   * (e.g. when a new filter is being applied, we always go to the top of the list)
   */
  private clearListAndApplyFilter$: Observable<ApplyFilterParams> =
    this.applyFilterAction$$.pipe(
      tap(() => {
        this.transactionsCache$$.next([]); // Cache is cleared here when this emits
        this.skipAndLimit$$.next(this.defaultSkipAndLimit);
      }),
      map(() => ({
        clearList: true,
      }))
    );

  /**
   * A stream to keep the list in place and trigger loading of additional transaction data
   *
   * (e.g. when new page of data is loaded at the bottom of the page we want to add to existing data)
   */
  private maintainListAndApplyFilter$: Observable<ApplyFilterParams> =
    this.nextPageAction$$.pipe(
      map(() => {
        return {
          clearList: false,
        };
      })
    );

  /**
   * A stream to convert the initial ApplyFilterParams into SortAndFilterParams
   * in preparation for the subsequent load.
   *
   * (regardless of whether we want to clear/maintain the list, this part is the same)
   */
  private filterAndSort$: Observable<FilterAndSortParams> = merge(
    this.clearListAndApplyFilter$,
    this.maintainListAndApplyFilter$
  ).pipe(
    withLatestFrom(this.filterArray$$, this.sortArray$$),
    map(
      ([applyFilterParams, filterArray, sortArray]: [
        ApplyFilterParams,
        FsxApiFilter[],
        FsxApiSort[]
      ]) => {
        const { clearList } = applyFilterParams;
        return {
          filterArray,
          sortArray,
          clearList,
        };
      }
    ),
    shareReplay()
  );

  /**
   * A stream to trigger loading of the transaction list data once we have the
   * filter, sort and paging data
   */
  private filingGridResult$: Observable<FilingGridResult> =
    this.filterAndSort$.pipe(
      withLatestFrom(this.skipAndLimit$$),
      switchMap(
        ([filterAndSort, skipAndLimit]: [
          FilterAndSortParams,
          SkipAndLimit
        ]) => {
          const { skip, limit } = skipAndLimit;
          return this.filingApiService.getDraftFilingEntitiyGridResult({
            filters: filterAndSort.filterArray,
            sort: filterAndSort.sortArray,
            skip,
            limit,
            exactTotal: true,
          });
        }
      )
    );

  /**
   * An output stream of loaded transactions as they are appended to the cahced transactions,
   * which may/may-not have been cleared in one of the dependent streams.
   */
  private filingGridResultTransactionsData$: Observable<TransactionsData> =
    this.filingGridResult$.pipe(
      withLatestFrom(this.transactionsCache$$),
      map(
        ([filingGridResult, transactionsCache]: [
          FilingGridResult,
          Filing[]
        ]) => {
          const combinedTransactions = [
            ...transactionsCache,
            ...filingGridResult.data,
          ];
          return {
            transactions: combinedTransactions,
            isLoading: false,
            pagingData: {
              count: filingGridResult.skip + filingGridResult.count,
              total: filingGridResult.total,
            },
          };
        }
      ),
      delay(500)
    );

  /**
   * An additional output stream that ensures we get a clear list and display the loading message
   * upfront before any loading is initiated.
   */
  private loadingTransactionsData$: Observable<TransactionsData> =
    this.filterAndSort$.pipe(
      filter((filterAndSort: FilterAndSortParams) => filterAndSort.clearList),
      map(() => {
        return {
          transactions: [],
          isLoading: true,
          pagingData: {
            count: 0,
            total: 0,
          },
        };
      })
    );

  /**
   * An output stream to remove a transaction from the transaction list and output the
   * updated list of transactions ready for display.
   */
  private transactionRemovedFromTransactionData$: Observable<TransactionsData> =
    this.transactionsEventService.transactionRemoved$.pipe(
      withLatestFrom(this.transactionsCache$$),
      map(([filing, transactions]: [Filing, Filing[]]) => {
        const filteredTransactions: Filing[] = transactions.filter(
          (f: Filing) => {
            return filing.id !== f.id;
          }
        );

        return {
          transactions: filteredTransactions,
          isLoading: false,
          pagingData: {
            count: 0,
            total: 0,
          },
        };
      })
    );

  /**
   * The overall output stream, which merges all other output streams (currently 3)
   * into a single output stream ensuring that the user always gets the latest data
   * whichever stream emit last
   */
  transactionsData$: Observable<TransactionsData> = merge(
    this.filingGridResultTransactionsData$,
    this.loadingTransactionsData$,
    this.transactionRemovedFromTransactionData$
  ).pipe(
    delay(0),
    tap((transactionData: TransactionsData) => {
      // Here we save the latest copy of the output transactions data,
      // which we use in the strems to ensure they're always working with the
      // most up to date copy of the data when they run.
      this.transactionsCache$$.next(transactionData.transactions);
    })
  );

  constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    private readonly transactionsEventService: TransactionsEventService
  ) {}

  /**
   * A method to apply any previosuly set filter
   */
  applyFilter(): void {
    this.applyFilterAction$$.next();
  }

  /**
   * A method to allow filters to be added to the locally stored filters
   *
   * @param filter The filter to be added
   */
  addFilter(filter: FsxApiFilter): void {
    this.removeFilters(filter.column, filter.sourceControl);

    const updatedFilters: FsxApiFilter[] = [
      ...this.filterArray$$.value,
      filter,
    ];

    this.filterArray$$.next(updatedFilters);
  }

  /**
   * A method to allow for the conditional removal of previously added filters
   *
   * @param column The column we want to remove existing filters for.
   * @param sourceControl The source control we want to remove existing filters for.
   */
  removeFilters(
    column: string,
    sourceControl: FsxApiFilterSourceControl
  ): void {
    // Always remove filters that originate from the same source control.
    this.removeFiltersForSourceControl(sourceControl);

    // Only keep filters that are either default filters or for a different column.
    const filteredFilters: FsxApiFilter[] = this.filterArray$$.value.filter(
      (f: FsxApiFilter) => {
        const isDifferentColumn = f.column !== column;
        const isDefaultFilter =
          f.sourceControl === FsxApiFilterSourceControl.DefaultFilter;
        const result = isDefaultFilter || isDifferentColumn;
        return result;
      }
    );
    this.filterArray$$.next(filteredFilters);
  }

  /**
   * A method to allow removal of all filters originating from a given source control.
   *
   * @param sourceControl The source control that we want to remove filters for.
   */
  removeFiltersForSourceControl(
    sourceControl: FsxApiFilterSourceControl
  ): void {
    // Only keep filters that are either default filters or from a different source control
    const filteredFilters: FsxApiFilter[] = this.filterArray$$.value.filter(
      (f: FsxApiFilter) => {
        const isDifferentSourceControl = f.sourceControl !== sourceControl;
        const isDefaultFilter =
          f.sourceControl === FsxApiFilterSourceControl.DefaultFilter;
        const result = isDefaultFilter || isDifferentSourceControl;
        return result;
      }
    );
    this.filterArray$$.next(filteredFilters);
  }

  /**
   * A method to allow clearing of all non-default filters.
   * (default filters cannot be cleared)
   */
  resetFilter(): void {
    const filteredFilters: FsxApiFilter[] = this.filterArray$$.value.filter(
      (f: FsxApiFilter) => {
        return f.sourceControl === FsxApiFilterSourceControl.DefaultFilter;
      }
    );
    this.filterArray$$.next(filteredFilters);
    this.sortArray$$.next([this.defaultSort]);
    this.applyFilter();
  }

  /**
   * A method to allow sorting by a specified column
   *
   * @param column The column to sort on
   */
  addSort(column: FilterColumnEnum): void {
    const newSortArray: FsxApiSort[] = this.sortArray$$.value.map(
      (cur: FsxApiSort) => {
        if (cur.column === column) {
          cur.direction =
            cur.direction === Direction.Ascending
              ? Direction.Descending
              : Direction.Ascending;
          return cur;
        } else {
          return { column, direction: Direction.Ascending };
        }
      },
      []
    );

    this.sortArray$$.next(newSortArray);
    this.applyFilter();
  }

  /**
   * A method to trigger retrieval of the next page of data.
   */
  nextPage(): void {
    const { skip, limit } = this.skipAndLimit$$.value;
    const newSkipAndLimit: SkipAndLimit = {
      limit,
      skip: skip + limit,
    };
    this.skipAndLimit$$.next(newSkipAndLimit);
    this.nextPageAction$$.next();
  }
}
