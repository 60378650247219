import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  CaseFilingContext,
  FilingState,
  FsxApiFilter,
  Filing,
  TRANSACTIONS_ACTIVITY,
  FsxApiFilterSourceControl,
  Operator,
} from '@fsx/fsx-shared';
import {
  FsxTextComponent,
  FsxBasicSingleSelectionComponent,
} from '@fsx/ui-components';
import moment, { Moment } from 'moment';
import { Subject, Observable, combineLatest, takeUntil, map } from 'rxjs';
import { ExistingFilingPanelComponent } from '../existing-filing-panel/existing-filing-panel.component';
import { NewFilingPanelComponent } from '../new-filing-panel/new-filing-panel.component';
import {
  FilingsService,
  FilterColumnEnum,
  FsxFilingsService,
  IFilingsService,
  TransactionsData,
} from '../services/filings.service';
import {
  FsxOpenFilingOrchestrationService,
  IOpenFilingOrchestrationService,
} from '../services/orchestration/open-filing-orchestration.service';
import { RemoveTransactionOrchestrationService } from '../services/orchestration/remove-transaction-orchestration.service';
import { BookmarkTransactionOrchestrationService } from '../services/orchestration/bookmark-transaction-orchestration.service';
import { FilingSubmissionReceiptService } from '../../filing-submission-receipt/filing-submission-receipt.service';
import { FilingCaptionTitleEnum } from '../../../../../../libs/shared/src/lib/pipes/filing-caption-title.pipe';

export enum FilingsListEnum {
  All = 'All',
  Last7Days = 'Last7Days',
  SubmittedByMe = 'SubmittedByMe',
  Bookmarked = 'Bookmarked',
}

export interface TransactionListViewModel {
  transactionsData: TransactionsData;
}

@Component({
  selector: 'fsx-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss'],
  providers: [
    {
      provide: FsxFilingsService,
      useClass: FilingsService,
    },
  ],
})
export class TransactionsListComponent implements AfterViewInit, OnDestroy {
  @ViewChild('searchText') searchTextFilterControl!: FsxTextComponent;
  @ViewChild('statusSingleSelection')
  statusFilterControl!: FsxBasicSingleSelectionComponent;
  @ViewChild('filingsSingleSelection')
  filingsFilterControl!: FsxBasicSingleSelectionComponent;

  private destroy$: Subject<void> = new Subject();

  public caseFilingContext: typeof CaseFilingContext = CaseFilingContext;

  public filingState: typeof FilingState = FilingState;

  public vm$: Observable<TransactionListViewModel> = combineLatest([
    this.filingsService.transactionsData$,
  ]).pipe(
    map(([transactionsData]: [TransactionsData]) => {
      // transactionsData.transactions = transactionsData.transactions.filter(t => t.status !== FilingState.Draft);
      return { transactionsData };
    })
  );

  filterColumnEnum = FilterColumnEnum;

  searchTextValue = '';

  defaultFieldDefinition = {
    required: false,
    minLength: 1,
    maxLength: 100,
    characterProfileName: 'alphanumericTextboxCharset',
    readOnly: false,
    visible: true,
  };

  // TODO: put the conditions that enable/disable these buttons here
  isDraftButtonDisabled = false;
  isAcceptedButtonDisabled = false;
  isRejectedButtonDisabled = false;
  isSubmittedButtonDisabled = false;

  statusFilterValue: string = '';

  statusList = [
    {
      caption: 'Scheduled',
      name: FilingState.Scheduled,
      sequence: 2,
      selected: false,
    },
    {
      caption: 'Submitted',
      name: FilingState.Submitted,
      sequence: 3,
      selected: false,
    },
    {
      caption: 'Rejected',
      name: FilingState.Rejected,
      sequence: 4,
      selected: false,
    },
    {
      caption: 'Accepted',
      name: FilingState.Accepted,
      sequence: 5,
      selected: false,
    },
    {
      caption: 'Cancelled',
      name: FilingState.Cancelled,
      sequence: 6,
      selected: false,
    },
    {
      caption: 'Partially Accepted',
      name: FilingState.PartiallyAccepted,
      sequence: 7,
      selected: false,
    },
    {
      caption: 'Received',
      name: FilingState.Received,
      sequence: 8,
      selected: false,
    },
    {
      caption: 'Received Under Review',
      name: FilingState.ReceivedUnderReview,
      sequence: 9,
      selected: false,
    },
    {
      caption: 'Rejected By EFM',
      name: FilingState.RejectedByEfm,
      sequence: 10,
      selected: false,
    },
  ];

  filingsList = [
    {
      caption: 'All',
      name: FilingsListEnum.All,
      sequence: 1,
      selected: true,
    },
    {
      caption: 'Submitted By Me',
      name: FilingsListEnum.SubmittedByMe,
      sequence: 2,
      selected: false,
    },
    {
      caption: 'Last 7 Days',
      name: FilingsListEnum.Last7Days,
      sequence: 3,
      selected: false,
    },
    {
      caption: 'Bookmarked',
      name: FilingsListEnum.Bookmarked,
      sequence: 4,
      selected: false,
    },
  ];

  dateFieldDefinition = {
    required: false,
    daysToDateRangeStart: -60,
    daysToDateRangeEnd: 60,
    timeZone: 'America/Chicago',
    readOnly: false,
    visible: true,
  };

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  private actionStreams$ = combineLatest([
    this.removeFilingOrchestrationService.removeTransaction$,
    this.bookmarkTransactionOrchestrationService.bookmarkTransaction$,
  ]);

  constructor(
    @Inject(FsxFilingsService) private readonly filingsService: IFilingsService,
    @Inject(FsxOpenFilingOrchestrationService)
    private readonly openFilingOrchestrationService: IOpenFilingOrchestrationService,
    private readonly bookmarkTransactionOrchestrationService: BookmarkTransactionOrchestrationService,
    private readonly removeFilingOrchestrationService: RemoveTransactionOrchestrationService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly filingSubmissionReceiptService: FilingSubmissionReceiptService
  ) {
    this.actionStreams$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngAfterViewInit(): void {
    const filter: FsxApiFilter = {
      column: FilterColumnEnum.Status,
      operator: Operator.NotEqual,
      value1: FilingState.Draft,
      sourceControl: FsxApiFilterSourceControl.DefaultFilter,
    };
    this.filingsService.addFilter(filter);
    this.filingsService.applyFilter();
  }

  onApplyClicked() {
    this.filingsService.applyFilter();
  }

  onSearchTextChanged(filterValue: string) {
    if (!filterValue) {
      this.filingsService.removeFilters(
        FilterColumnEnum.Search,
        FsxApiFilterSourceControl.SearchText
      );
      return;
    }
    const filter: FsxApiFilter = {
      column: FilterColumnEnum.Search,
      operator: Operator.Equal,
      value1: filterValue,
      sourceControl: FsxApiFilterSourceControl.SearchText,
    };
    this.filingsService.addFilter(filter);
  }

  onStatusFilterSelected(filterValue: string | FilingState) {
    if (!filterValue) {
      return;
    }
    const filter: FsxApiFilter = {
      column: FilterColumnEnum.Status,
      operator: Operator.Equal,
      value1: filterValue,
      sourceControl: FsxApiFilterSourceControl.Status,
    };
    this.filingsService.addFilter(filter);
  }

  onStatusFilterCleared() {
    this.filingsService.removeFilters(
      FilterColumnEnum.Status,
      FsxApiFilterSourceControl.Status
    );
  }

  onSortIconClicked(column: FilterColumnEnum) {
    this.filingsService.addSort(column);
  }

  onQuickStatusFilterButtonClicked(value: FilingState) {
    this.statusFilterValue = value;
    this.onStatusFilterSelected(value);
    this.filingsService.applyFilter();
  }

  onDateRangeChanged() {
    const mStartDate = moment(this.range.value.start);
    const mEndDate = moment(this.range.value.end);
    this.setDateRangeFilter(
      mStartDate,
      mEndDate,
      FsxApiFilterSourceControl.DateRange
    );
  }

  onFilingsFilterChanged(value: string) {
    if (value === FilingsListEnum.Last7Days) {
      const mStartDate = moment().subtract(7, 'd');
      const mEndDate = moment();
      this.range.setValue({
        start: mStartDate.toDate(),
        end: mEndDate.toDate(),
      });
      this.setDateRangeFilter(
        mStartDate,
        mEndDate,
        FsxApiFilterSourceControl.FilingsDropdown
      );
    } else if (value === FilingsListEnum.Bookmarked) {
      this.filingsService.addFilter({
        column: FilterColumnEnum.Bookmarked,
        operator: Operator.IsTrue,
        value1: null,
        sourceControl: FsxApiFilterSourceControl.FilingsDropdown,
      });
    } else {
      this.filingsService.removeFiltersForSourceControl(
        FsxApiFilterSourceControl.FilingsDropdown
      );
    }
  }

  private setDateRangeFilter(
    mStartDate: Moment,
    mEndDate: Moment,
    sourceControl: FsxApiFilterSourceControl
  ) {
    const startDateTime: string = mStartDate.format('YYYY-MM-DD HH:mm:ss');
    const endDateTime: string = mEndDate
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss');
    const filter: FsxApiFilter = {
      column: FilterColumnEnum.CreatedAt,
      operator: Operator.Between,
      value1: startDateTime,
      value2: endDateTime,
      sourceControl: sourceControl,
    };
    this.filingsService.addFilter(filter);
  }

  onNewCaseButtonClicked() {
    this.dialog.open(NewFilingPanelComponent, {
      data: {
        reference: this.dialog,
      },
      width: '500px',
      height: '100vh',
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
  }

  public onExistingCaseButtonClicked(): void {
    this.dialog.open(ExistingFilingPanelComponent, {
      data: {
        reference: this.dialog,
      },
      width: '500px',
      height: '100vh',
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    });
  }

  onTableRowClicked(filing: Filing) {
    this.openFilingOrchestrationService.openFiling(filing);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onBookmarkedClicked(event: Event, filing: Filing) {
    event.stopPropagation();
    this.bookmarkTransactionOrchestrationService.bookmarkTransaction(filing);
    filing.bookmarked = !filing.bookmarked;
  }

  onTransactionIdClicked(event: Event, filing: Filing) {
    event.stopPropagation();
    this.router.navigate([TRANSACTIONS_ACTIVITY, filing.id]);
  }

  onEditIconClicked(event: Event, filing: Filing) {
    event.stopPropagation();
    if (filing.status !== this.filingState.Draft) {
      return;
    }
    this.openFilingOrchestrationService.openFiling(filing);
  }

  onReturnCorrectionsClicked(event: Event, _filing: Filing) {
    event.stopPropagation();
    return;
  }

  onReceiptIconClicked(event: Event, _filing: Filing) {
    event.stopPropagation();
    this.filingSubmissionReceiptService.openFilingSubmissionReceipt(_filing.id);
  }

  onStartSubFClicked(event: Event, _filing: Filing) {
    event.stopPropagation();
    return;
  }

  onManageServiceContactsClicked(event: Event, _filing: Filing) {
    event.stopPropagation();
    return;
  }

  onServiceContactReportClicked(event: Event, _filing: Filing) {
    event.stopPropagation();
    return;
  }

  onRmoveTransactionClicked(event: Event, filing: Filing) {
    event.stopPropagation();
    if (filing.status !== this.filingState.Draft) {
      return;
    }
    this.removeFilingOrchestrationService.removeTransaction(filing);
  }

  onResetFilterClicked() {
    this.resetFilterControls();
    this.filingsService.resetFilter();
  }

  private resetFilterControls() {
    this.searchTextFilterControl.clearText();

    this.range.setValue({ start: null, end: null });

    this.statusFilterControl.clearSelection();

    this.filingsFilterControl.clearSelection();
  }

  onScrollDown() {
    this.filingsService.nextPage();
  }

  protected readonly FilingCaptionTitleEnum = FilingCaptionTitleEnum;
  protected readonly FilingState = FilingState;
}
