<!-- START: Status Container -->
<div *ngIf="!!requestTypes && !!filingState" class="status-container">
  <ng-container [ngSwitch]="requestTypesJoined">
    <!-- START: Filing Option - File Only   -->
    <div
      *ngSwitchCase="RequestTypesJoined.FileOnly"
      [ngSwitch]="filingState"
      class="rectangle file-only"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <!-- START: Filing Option - Accepted -->
      <ng-container *ngSwitchCase="FilingState.Accepted">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active">Accepted</span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Accepted -->

      <!-- START: Filing Option - Submitted -->
      <ng-container *ngSwitchCase="FilingState.Submitted">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active">Submitted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Submitted -->

      <!-- START: Filing Option - Rejected -->
      <ng-container *ngSwitchCase="FilingState.Rejected">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="rejected">
          <span fxFlex="none" class="status active">Rejected</span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Rejected -->

      <!-- START: Filing Option - Partially Accepted -->
      <ng-container *ngSwitchCase="FilingState.PartiallyAccepted">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="partial">
          <span fxFlex="none" class="status active" fxLayout="column">
            <span fxFlex="none">Partially</span>
            <span fxFlex="none" class="lower">Accepted</span>
          </span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Partially Accepted -->

      <!-- START: Filing Option - Received -->
      <ng-container *ngSwitchCase="FilingState.Received">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active">Received</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Received -->

      <!-- START: Filing Option - Received Under Review -->
      <ng-container *ngSwitchCase="FilingState.ReceivedUnderReview">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active" fxLayout="column">
            <span fxFlex="none">Under</span>
            <span fxFlex="none" class="lower"> Review</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Received Under Review -->

      <!-- START: Filing Option - Cancelled -->
      <ng-container *ngSwitchCase="FilingState.Cancelled">
        <div fxFlex="14px" class="cancelled">
          <span fxFlex="none" class="status active">Cancelled</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status" fxLayout="column">
            <span fxFlex="none" class="lower">Received</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
      </ng-container>
      <!-- START: Filing Option - Cancelled -->

      <!-- START: Filing Option - Rejected By EFM -->
      <ng-container *ngSwitchCase="FilingState.RejectedByEfm">
        <div fxFlex="14px" class="rejected">
          <span fxFlex="none" class="status active" fxLayout="column">
            <span fxFlex="none">Failed</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status" fxLayout="column">
            <span fxFlex="none" class="lower">Received</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
      </ng-container>
      <!-- START: Filing Option - Rejected By EFM -->
    </div>
    <!-- END: Filing Option - File Only   -->

    <!-- START: Filing Option - File and Serve   -->
    <div
      *ngSwitchCase="RequestTypesJoined.FileAndServe"
      [ngSwitch]="filingState"
      class="rectangle file-and-serve"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <!-- START: Status - Accepted -->
      <ng-container *ngSwitchCase="FilingState.Accepted">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active">Accepted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- END: Status - Accepted -->

      <!-- START: Status - Submitted -->
      <ng-container *ngSwitchCase="FilingState.Submitted">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active">Submitted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- END: Status - Submitted -->

      <!-- START: Status - Rejected -->
      <ng-container *ngSwitchCase="FilingState.Rejected">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="rejected">
          <span fxFlex="none" class="status active">Rejected</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- END: Status - Rejected -->

      <!-- Start: Status - Partially Accepted -->
      <ng-container *ngSwitchCase="FilingState.PartiallyAccepted">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Received</span>
        </div>
        <div fxFlex="14px" class="partial">
          <span fxFlex="none" class="status active" fxLayout="column">
            <span fxFlex="none">Partially</span>
            <span fxFlex="none" class="lower">Accepted</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- END: Status - Partially Accepted -->

      <!-- START: Filing Option - Received -->
      <ng-container *ngSwitchCase="FilingState.Received">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active">Received</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Received -->

      <!-- START: Filing Option - Received Under Review -->
      <ng-container *ngSwitchCase="FilingState.ReceivedUnderReview">
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status">Submitted</span>
        </div>
        <div fxFlex="14px" class="check">
          <span fxFlex="none" class="status active" fxLayout="column">
            <span fxFlex="none">Under</span>
            <span fxFlex="none" class="lower"> Review</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- END: Filing Option - Received Under Review -->

      <!-- START: Filing Option - Cancelled -->
      <ng-container *ngSwitchCase="FilingState.Cancelled">
        <div fxFlex="14px" class="cancelled">
          <span fxFlex="none" class="status active">Cancelled</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status" fxLayout="column">
            <span fxFlex="none" class="lower">Received</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- START: Filing Option - Cancelled -->

      <!-- START: Filing Option - Rejected By EFM -->
      <ng-container *ngSwitchCase="FilingState.RejectedByEfm">
        <div fxFlex="14px" class="rejected">
          <span fxFlex="none" class="status active" fxLayout="column">
            <span fxFlex="none">Failed</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status" fxLayout="column">
            <span fxFlex="none" class="lower">Received</span>
          </span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Accepted</span>
        </div>
        <div fxFlex="14px" class="empty">
          <span fxFlex="none" class="status">Served</span>
        </div>
      </ng-container>
      <!-- START: Filing Option - Rejected By EFM -->
    </div>
    <!-- END: Filing Option - File and Serve   -->
  </ng-container>
</div>
<!-- END: Status Container -->
