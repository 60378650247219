import { Pipe, PipeTransform } from '@angular/core';
import { RequestDocument } from '../types';

@Pipe({
  name: 'leadOrSupporting',
})
export class LeadOrSupportingPipe implements PipeTransform {
  transform(requestDocument: RequestDocument): string {
    return requestDocument.isLeadDocument
      ? 'Lead Document'
      : 'Supporting Document';
  }
}
