import { Component, Input } from '@angular/core';
import { Filing, CaseRequestViewModel, FilingProfile } from '@fsx/fsx-shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'fsx-filing-content',
  templateUrl: './filing-content.component.html',
  styleUrls: ['./filing-content.component.scss'],
})
export class FilingContentComponent {
  @Input() filing$: Observable<Filing | null> | null = null;
  @Input() filingProfile$: Observable<FilingProfile | null> | null = null;
  @Input() caseRequest$: Observable<CaseRequestViewModel | null> | null = null;
}
