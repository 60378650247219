import { Pipe, PipeTransform } from '@angular/core';
import { DocumentInfo, RenderingStatus } from '@fsx/fsx-shared';
import { DocumentInfoHelperService } from 'projects/libs/shared/src/lib/services/filings/document-info-helper.service';

@Pipe({
  name: 'convertedPdfStatus',
})
export class ConvertedPdfStatusPipe implements PipeTransform {
  constructor(private documentInfoHelperService: DocumentInfoHelperService) {}

  transform(documentInfo: DocumentInfo): RenderingStatus {
    return this.documentInfoHelperService.getConvertedPdfStatus(documentInfo);
  }
}
