import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  of,
  share,
  switchMap,
} from 'rxjs';
import {
  ContactFormMode,
  ContactProfile,
  ContactViewModel,
  FsxContactApiService,
  IContactApiService,
} from '@fsx/fsx-shared';
import { FsxPanelService, IPanelService } from './panel.service';
import {
  FsxContactsListService,
  IContactsListService,
} from '../../contacts/contacts-list/contacts-list.service';
import {
  FsxContactProfileDataService,
  IContactProfileDataService,
} from '../../filing-editor/services/contact-profile-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IOpenContactFormOrchestrationService to use at runtime.
 */
export const FsxOpenContactFormOrchestrationService =
  new InjectionToken<IOpenContactFormOrchestrationService>(
    'FsxOpenContactFormOrchestrationService'
  );

/**
 * The parameters to pass to the orchestration service so
 * that it can do its work.
 */
export interface OpenContactFormOrchestrationParams {
  formMode: ContactFormMode;
  contactId?: string;
}

/**
 * A blueprint for an orchestration service, which handles the opening of the contacts form.
 */
export interface IOpenContactFormOrchestrationService {
  /**
   * The orchestration steps needed to open the contacts form.
   */
  openContactFormOrchestration$: Observable<void>;

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean>;

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openContactForm(params: OpenContactFormOrchestrationParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the opening of
 * the contacts form.
 */
@Injectable()
export class OpenContactFormOrchestrationService
  implements IOpenContactFormOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private openContactFormAction$ =
    new Subject<OpenContactFormOrchestrationParams>();

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * stored in a BehaviorSubject.
   */
  private isOrchestrationInProgress$$ = new BehaviorSubject<boolean>(false);

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean> =
    this.isOrchestrationInProgress$$.asObservable();

  /**
   * The orchestration steps needed to open the contacts form.
   */
  openContactFormOrchestration$: Observable<void> =
    this.openContactFormAction$.pipe(
      switchMap((params: OpenContactFormOrchestrationParams) => {
        const { contactId, formMode } = params;

        this.isOrchestrationInProgress$$.next(true);

        // TODO: Remove when contacts form has been refactore to use the
        // contactsFormConfig.contact object.
        if (contactId) {
          this.contactsListService.setSelectedContactId(contactId);
        } else {
          this.contactsListService.setSelectedContactId(null);
        }

        // Create an observable of the looked up contact (if contactId was provided),
        // otherwise make the observable return null.
        const contact$: Observable<ContactViewModel | null> = contactId
          ? this.contactApiService.getContact(contactId)
          : of(null);

        // Here we subscribe to the contact$ observable using the switchMap operator.
        return contact$.pipe(
          switchMap((contact: ContactViewModel | null) => {
            return this.contactApiService.getContactProfile().pipe(
              map((contactProfile: ContactProfile) => {
                this.contactProfileDataService.setContactProfileData(
                  contactProfile
                );
                this.isOrchestrationInProgress$$.next(false);
                // Make the call to open the panel
                this.panelService.openContactsFormPanel({
                  contactsFormConfig: { formMode, contact, contactProfile },
                  injector: this.injector,
                });
                return;
              })
            );
          })
        );
      }),
      // Ensures that the orchestration runs once, even when there are multiple subscribers.
      // Note: share() is preferred to shareReplay() here since we're not interested in the last value.
      share()
    );

  /**
   *
   * @param contactApiService The API service which we use to look up the contact for the given contactId.
   * @param contactsListService The ui state service used to hold the selectedContactId and derived a contact from.
   * @param panelService The utility service for opening panels.
   * @param injector The injector to pass on to the mat dialog, used to ensure the dialog uses the same providers.
   */
  public constructor(
    @Inject(FsxContactProfileDataService)
    private readonly contactProfileDataService: IContactProfileDataService,
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxContactsListService)
    private readonly contactsListService: IContactsListService,
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    private readonly injector: Injector
  ) {}

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openContactForm(params: OpenContactFormOrchestrationParams): void {
    this.openContactFormAction$.next(params);
  }
}
