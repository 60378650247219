import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  FilingProfile,
  PartiesFormService,
  ContactViewModel,
  RequestParticipantViewModel,
  ParticipantSpec,
  FsxPartiesFormService,
} from '@fsx/fsx-shared';
import { filter, map, Observable } from 'rxjs';

export interface ICreateParticipantFromContactParams {
  contact: ContactViewModel;
  uniqueIdentifier: string;
  participantSpec: ParticipantSpec;
  filingProfile: FilingProfile;
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the ICreateParticipantService to use at runtime.
 */
export const FsxCreateParticipantService =
  new InjectionToken<ICreateParticipantService>('FsxCreateParticipantService');

export interface ICreateParticipantService {
  createParticipantFromContact(
    params: ICreateParticipantFromContactParams
  ): Observable<RequestParticipantViewModel>;
}

@Injectable()
export class CreateParticipantService implements ICreateParticipantService {
  constructor(
    @Inject(FsxPartiesFormService)
    private readonly partiesFormService: PartiesFormService
  ) {}

  public createParticipantFromContact(
    params: ICreateParticipantFromContactParams
  ): Observable<RequestParticipantViewModel> {
    return this.partiesFormService
      .transformContactToParticipant(
        params.contact,
        params.participantSpec,
        params.filingProfile
      )
      .pipe(
        filter(
          (participant: RequestParticipantViewModel | undefined) =>
            !!participant
        ),
        map(
          (participant: RequestParticipantViewModel | undefined) =>
            participant as RequestParticipantViewModel
        ),
        map((participant: RequestParticipantViewModel) => {
          participant.name = params.uniqueIdentifier;
          return participant;
        })
      );
  }
}
