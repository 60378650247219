import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CasePartyViewModel, CaseRequestViewModel } from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, combineLatest, filter, map } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from './case-request-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IPartyDataService to use at runtime.
 */
export const FsxPartyDataService = new InjectionToken<IPartyDataService>(
  'FsxPartyDataService'
);

/**
 * A blueprint for a state service for storing an array of CaseParty objects.
 */
export interface IPartyDataService {
  /**
   * A public member, which combines "Case Parties" with "Case Request Parties"
   * and exposes the result as an Observable of CaseParty objects.
   */
  parties$: Observable<CasePartyViewModel[]>;

  /**
   * A public method, which sets the array of CaseParty objects
   */
  setCaseParties(parties: CasePartyViewModel[]): void;
}

/**
 * A concrete implementation of a state service for storing an array of CaseParty objects.
 */
@Injectable()
export class PartyDataService implements IPartyDataService {
  /**
   * A private member, which stores the "Case Parties" as an array of CaseParty objects in
   * a BehaviorSubject.
   */
  private caseParties$$ = new BehaviorSubject<CasePartyViewModel[] | null>(
    null
  );

  /**
   * A private member, which exposes the "Case Parties" as an observable.
   */
  private caseParties$ = this.caseParties$$.asObservable().pipe(
    filter((parties) => parties !== null),
    map((parties) => parties as CasePartyViewModel[])
  );

  /**
   * A private member, which exposes the "Case Request Parties" as an observable.
   */
  private caseRequestParties$ = this.caseRequestDataService.caseRequest$.pipe(
    map((caseRequest: CaseRequestViewModel) => {
      const caseRequestParties = caseRequest.parties || [];

      // Iterate through the caseRequestParties and assign the caseRequestIndex property.
      // This is used in subsequent PATCH requests to the server when attempting updates.
      const indexedCaseRequestParties = caseRequestParties.map(
        (party: CasePartyViewModel, index: number) => {
          party.caseRequestPartyIndex = index;
          return party;
        }
      );

      return indexedCaseRequestParties;
    })
  );

  /**
   * A public member, which combines "Case Parties" with "Case Request Parties"
   * and exposes the result as an Observable of CaseParty objects.
   */
  parties$: Observable<CasePartyViewModel[]> = combineLatest([
    this.caseParties$,
    this.caseRequestParties$,
  ]).pipe(
    map(
      ([caseParties, caseRequestParties]: [
        CasePartyViewModel[],
        CasePartyViewModel[]
      ]) => {
        // Combine the "Case Parties" CaseParty objects with the "Case Request Parties" objects here...

        // Derive a list of ParticipantNames from the "Case Request Parties" that exist on the CaseRequest.
        // - We will use these to remove any duplicate CaseParty objects next.
        const caseRequestPartyNames = caseRequestParties.map(
          (p) => p.participantName
        );

        // Exclude any "Case Parties" that are already "Case Request Parties"
        const filteredCaseParties = caseParties.filter((p) => {
          const exists = caseRequestPartyNames.indexOf(p.participantName) > -1;
          return !exists;
        });

        // Combine the "Case Request Parties" with the filtered "Case Parties" into a single array.
        const parties = [...caseRequestParties, ...filteredCaseParties];

        // Sort the array of "Case Parties" and "Case Request Parties" consistently
        const sortedParties = parties.sort(
          (partyA: CasePartyViewModel, partyB: CasePartyViewModel) => {
            if (!partyA.efmKey) {
              return 2;
            }
            if (!partyB.efmKey) {
              return -1;
            }
            if (partyA.caption < partyB.caption) {
              return -1;
            }
            if (partyA.caption > partyB.caption) {
              return 1;
            }
            return 0;
          }
        );

        // Return the sorted array
        return sortedParties;
      }
    )
  );

  /**
   * @param caseRequestDataService Where we get the "Case Request Parties" from.
   */
  constructor(
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService
  ) {}

  /**
   * A public method, which sets the array of CaseParty objects
   *
   * @param parties The array of CaseParty objects to store
   */
  setCaseParties(parties: CasePartyViewModel[]): void {
    const newPartiesObj = JSON.parse(JSON.stringify(parties));
    this.caseParties$$.next(newPartiesObj);
  }
}
