import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CombinedFilingData,
  DocumentCommonCategory,
  DocumentInfo,
  DocumentInfoHelperService,
  FileUploadState,
  FilingProfileHelperService,
  RequestDocumentViewModel,
} from '@fsx/fsx-shared';
import { FilesUploadedFromAdditionalFieldEventParams } from '../document-form/document-form.component';
import { DocumentsGridRow } from '../documents-grid/documents-grid.model';
import { Moment } from 'moment';
import moment from 'moment';
import { IUploadedFile } from '@fsx/ui-components';
import { FileUploadStatusComponent } from '../file-upload-status/file-upload-status.component';
import {
  FsxUpdateDocumentOrchestrationService,
  IUpdateDocumentOrchestrationService,
} from '../services/update-document-orchestration.service';

export interface RemoveDocumentEventParams {
  requestDocument: RequestDocumentViewModel;
  documentInfo?: DocumentInfo;
}

export interface RowExpandedEventParams {
  documentGridRow: DocumentsGridRow;
}

export interface ISupportingFilesUploadedEventParams {
  requestDocument: RequestDocumentViewModel;
  uploadedFiles: IUploadedFile[];
  rowIndex: number;
}

export interface IDocumentFileUploadedEventParams {
  requestDocument: RequestDocumentViewModel;
  uploadedFile: IUploadedFile;
}

export interface IDocumentErrorsChangedEventParams {
  rowIndex: number;
  requestDocument: RequestDocumentViewModel | null;
}

@Component({
  selector: 'fsx-documents-grid-item',
  templateUrl: './documents-grid-item.component.html',
  styleUrls: [
    '../documents-grid/documents-grid.component.scss',
    './documents-grid-item.component.scss',
  ],
})
export class DocumentsGridItemComponent implements OnChanges {
  @Input() rowIndex!: number;
  @Input() documentsGridRow!: DocumentsGridRow;
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() documentOrderNumber!: string;
  @Output() removeDocumentEvent = new EventEmitter<RemoveDocumentEventParams>();
  @Output() toggleExpandRowEvent = new EventEmitter<DocumentsGridRow>();
  @Output() cancelUploadEvent = new EventEmitter<DocumentsGridRow>();
  @Output() supportingFilesUploadedEvent =
    new EventEmitter<ISupportingFilesUploadedEventParams>();
  @Output() documentFileUploadedEvent =
    new EventEmitter<IDocumentFileUploadedEventParams>();
  @Output() documentErrorChangedEvent =
    new EventEmitter<IDocumentErrorsChangedEventParams>();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  @ViewChild('fileUploadStatusField')
  fileUploadStatusField!: FileUploadStatusComponent;

  displayDocumentCategory!: boolean;
  hasSupportingDocumentSpecs!: boolean;
  validationTimestamp!: Moment;

  fileUploadStateEnum: typeof FileUploadState = FileUploadState;

  constructor(
    @Inject(FsxUpdateDocumentOrchestrationService)
    private readonly updateDocumentOrchestrationService: IUpdateDocumentOrchestrationService,
    private readonly documentsInfoHelperService: DocumentInfoHelperService,
    private readonly filingProfileHelperService: FilingProfileHelperService
  ) {}

  ngOnChanges(): void {
    this.setDisplayDocumentCategory();

    this.hasSupportingDocumentSpecs =
      this.filingProfileHelperService.hasSupportingDocumentSpecs(
        this.combinedFilingData
      );
  }

  private setDisplayDocumentCategory() {
    const isConvertedPdfStatusReady: boolean =
      this.documentsInfoHelperService.isConvertedPdfStatusReady(
        this.documentsGridRow.documentInfo
      );
    const hasDocumentCategory: boolean =
      !!this.documentsGridRow.requestDocument.category;
    const hasDocumentInfo: boolean = !!this.documentsGridRow.documentInfo;
    const hasDocumentCategoryAndNoDocumentInfo: boolean =
      hasDocumentCategory && !hasDocumentInfo;
    const hasDocumentCategoryAndConvertedPdfStatusIsReady: boolean =
      hasDocumentCategory && isConvertedPdfStatusReady;
    this.displayDocumentCategory =
      hasDocumentCategoryAndNoDocumentInfo ||
      hasDocumentCategoryAndConvertedPdfStatusIsReady;
  }

  onToggleExpandDetailRow(event: Event) {
    event.stopPropagation();
    this.toggleExpandRowEvent.emit(this.documentsGridRow);

    if (!this.documentsGridRow.expanded) {
      this.validateElements();
    }
  }

  onRemoveDocumentClicked(event: Event) {
    event.stopPropagation();
    const removeDocumentEventParams: RemoveDocumentEventParams = {
      ...this.documentsGridRow,
    };
    this.removeDocumentEvent.emit(removeDocumentEventParams);

    this.documentErrorChangedEvent.emit({
      rowIndex: this.documentsGridRow.rowIndex,
      requestDocument: null,
    });
  }

  onDocumentCheckboxClicked(event: Event) {
    event.stopPropagation();
  }

  cancelUploadEventHandler() {
    this.cancelUploadEvent.emit(this.documentsGridRow);
  }

  supportingFilesUploadedEventHandler(uploadedFiles: IUploadedFile[]): void {
    const { requestDocument, rowIndex } = this.documentsGridRow;
    this.supportingFilesUploadedEvent.emit({
      requestDocument,
      uploadedFiles,
      rowIndex,
    });
  }

  onFileDropped(files: FileList): void {
    const { requestDocument } = this.documentsGridRow;
    const uploadedFile: IUploadedFile = {
      file: files[0],
      sourceId: requestDocument.id!,
    };

    const params: IDocumentFileUploadedEventParams = {
      requestDocument,
      uploadedFile,
    };
    this.documentFileUploadedEvent.emit(params);
  }

  onToggleLeadOrSupportingDocument(event: Event) {
    event.stopPropagation();
    if (this.documentsGridRow.rowIndex === 0) {
      return;
    }

    const isLeadDocument =
      !this.documentsGridRow.requestDocument.isLeadDocument;

    // categories are dependent upon lead or supporting
    this.documentsGridRow.requestDocument.category = {
      name: '',
      caption: '',
      commonCategory: isLeadDocument
        ? DocumentCommonCategory.LeadDocument
        : DocumentCommonCategory.SupportingDocument,
    };

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      isLeadDocument: isLeadDocument,
      category: this.documentsGridRow.requestDocument.category,
    };

    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: this.documentsGridRow.requestDocument,
      partialRequestDocument,
    });
  }

  private validateElements() {
    // set the new timestamp, to trigger validation
    this.validationTimestamp = moment();

    if (this.fileUploadStatusField) {
      this.fileUploadStatusField.validate();
    }
  }

  public filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedFromAdditionalFieldEventParams
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }
}
