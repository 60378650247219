import { Inject, Injectable } from '@angular/core';
import {
  NewFiling,
  Filing,
  RequestCase,
  CourtsApiService,
  CaseDetail,
} from '@fsx/fsx-shared';
import { map, Observable, Subject, switchMap, tap } from 'rxjs';
import { FsxFilingApiService, IFilingApiService } from './filing-api.service';

@Injectable({
  providedIn: 'root',
})
export class NewFilingService {
  private newFilingCreated$$ = new Subject<Filing>();
  public newFilingCreated$ = this.newFilingCreated$$.asObservable();

  public constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    private readonly courtApiService: CourtsApiService
  ) {}

  public createNewFiling(newFiling: NewFiling): Observable<Filing> {
    return this.filingApiService.createFiling(newFiling).pipe(
      switchMap((filing: Filing) => {
        const efmKey = newFiling.cases[0]?.efmKey || undefined;

        const requestCase: RequestCase = {
          caption: newFiling.caption,
          title: newFiling.cases[0]?.title,
          efmKey: efmKey,
          caseId: '',
          amountInControversy: null,
          incidentAddress: null,
          isComplexCase: false,
        } as RequestCase;

        // This should be set to '00000000-0000-0000-0000-000000000000' in order to unblock James for JTI,
        // The reason being that the parties returned MUST have their caseIds match that of the Case's caseId.
        // Prior to this, the parties returned from JTI had their caseIds set to '00000000-0000-0000-0000-000000000000'
        // whereas the case's caseId was set to the filing's.
        requestCase.caseId = '00000000-0000-0000-0000-000000000000';
        // requestCase.caseId = filing.id;

        const newFiling$ = this.filingApiService.putCaseRequest(
          requestCase,
          filing.id as string
        );

        const existingFiling$ = this.courtApiService
          .getCaseDetail(newFiling.courtId, efmKey!)
          .pipe(
            switchMap((caseDetail: CaseDetail) => {
              requestCase.caseId = caseDetail.summary.id;
              return this.filingApiService.putCaseRequest(
                requestCase,
                filing.id as string
              );
            })
          );

        const newOrExisting$ = efmKey ? existingFiling$ : newFiling$;

        return newOrExisting$.pipe(
          map(() => {
            return filing;
          })
        );
      }),
      tap((filing: Filing) => {
        this.newFilingCreated$$.next(filing);
      })
    );
  }
}
