import { Injectable } from '@angular/core';

export enum StorageTypeEnum {
  Session = 'session',
  Local = 'local',
}

export interface StorageResponse<T = unknown> {
  value: T;
  originalState?: unknown;
}

interface StorageOptionsBase {
  key: string;
  type?: StorageTypeEnum;
}

export interface StorageReturnOptions extends StorageOptionsBase {
  returnOriginalValue: boolean;
}

export interface StorageOptions<T> extends StorageOptionsBase {
  value: T;
  createDiff?: boolean;
}

export interface InternalStorageOptions<T> extends StorageOptions<T> {
  valueAsStr: string;
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private mapOfDiffs = new Map<string, string>();
  private localStorage: Storage = localStorage;
  private sessionStorage: Storage = sessionStorage;

  public toStorage<T>(storageObj: StorageOptions<T>): void {
    if (!storageObj.value) {
      return;
    }

    const internalObj = this.createInternalOptions<T>(storageObj);

    return this.saveToStorage(internalObj);
  }

  public getFromStorage<T>(
    storageObj: StorageReturnOptions
  ): StorageResponse<T> {
    return { value: this.fetchFromStorage(storageObj) };
  }

  public removeOneFromStorage(
    key: string,
    location: StorageTypeEnum = StorageTypeEnum.Local
  ) {
    const keyExists = this.mapOfDiffs.has(key);
    if (keyExists) {
      this.mapOfDiffs.delete(key);
    }

    if (location === StorageTypeEnum.Local) {
      return this.localStorage.removeItem(key);
    }
    return this.sessionStorage.removeItem(key);
  }

  public removeAllFromStorage() {
    this.localStorage.clear();
    this.mapOfDiffs = new Map<string, string>();
  }

  private fetchFromStorage(storageObj: StorageReturnOptions) {
    const data =
      storageObj.type === StorageTypeEnum.Local || !storageObj.type
        ? this.localStorage.getItem(storageObj.key)
        : this.sessionStorage.getItem(storageObj.key);

    if (data === null) {
      return;
    }
    return JSON.parse(data);
  }

  private saveToStorage<T>(storageObj: InternalStorageOptions<T>): void {
    if (storageObj.createDiff) {
      this.createSessionDiff(storageObj.key, storageObj.valueAsStr);
    }

    if (!storageObj.type || storageObj.type === StorageTypeEnum.Local) {
      return this.localStorage.setItem(storageObj.key, storageObj.valueAsStr);
    }
    return this.sessionStorage.setItem(storageObj.key, storageObj.valueAsStr);
  }

  private createSessionDiff(key: string, value: string): 'set' | 'notSet' {
    const keyExists = this.mapOfDiffs.has(key);
    if (keyExists) {
      return 'notSet';
    }
    this.mapOfDiffs.set(`${key}_original`, value);
    return 'set';
  }

  private createInternalOptions<T>(
    data: StorageOptions<T>
  ): InternalStorageOptions<T> {
    return Object.assign({}, data, { valueAsStr: JSON.stringify(data.value) });
  }
}
