import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

@Component({
  selector: 'fsx-filing-message',
  standalone: true,
  imports: [CommonModule, FlexModule],
  templateUrl: './filing-message.component.html',
  styleUrls: ['./filing-message.component.css'],
})
export class FilingMessageComponent {
  /**
   * The description of the rejection.
   */
  @Input() description: string | null | undefined;

  /**
   * Whether to show error state or message state.
   */
  @Input() isError = false;

  /**
   * Whether to show error state or message state.
   */
  @Input() isFailure = false;
}
