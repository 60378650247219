import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to format a transaction id. (PURELY COSMETIC
 */
@Pipe({
  name: 'transactionId',
})
export class TransactionIdPipe implements PipeTransform {
  /**
   * Transforms a filing id to a more legible format.
   * @param filingId
   */
  transform(filingId: string | null | undefined): string {
    if (!!filingId) {
      return filingId.replace(/\D+/g, '').slice(0, 8); // ToDo: PURELY COSMETIC. Removes non-digits and cuts it to length of 8
    }
    return '-';
  }
}
