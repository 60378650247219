import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ContactSummary,
  ContactType,
  EmailCommonCategory,
  EnvConfig,
  FsxContact,
  IAuthService,
  JwtUser,
} from '@fsx/fsx-shared';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ENV_CONFIG } from '../app-config/app-config.service';
import { ApplicationInsightsService } from '../app-insights/application-insights.service';
import {
  FsxUserApiService,
  IUserApiService,
} from '../../users/user-api.service';
import {
  FsxUserDataService,
  IUserDataService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/user-data.service';

export type DoesAccessTokenExist = boolean;
type hasSessionUserBeenSet = boolean;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {
  private sessionUser$: BehaviorSubject<FsxContact | null> =
    new BehaviorSubject<FsxContact | null>(null);
  private isAuthenticated$ = new BehaviorSubject<boolean>(false);

  public get isAuthenticated(): Observable<boolean> {
    return of(this.oauthService.hasValidAccessToken());
  }

  public get sessionUser(): Observable<FsxContact | null> {
    return this.sessionUser$.asObservable();
  }

  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public constructor(
    @Inject(ENV_CONFIG) private readonly envConfig: Observable<EnvConfig>,
    private readonly oauthService: OAuthService,
    private readonly router: Router,
    private readonly applicationInsightsService: ApplicationInsightsService,
    @Inject(FsxUserApiService) private readonly userApiService: IUserApiService,
    @Inject(FsxUserDataService)
    private readonly userDataService: IUserDataService
  ) {
    this.setSessionStorageListener();
    this.setOAuthServiceEventListener();
    this.oauthService.setupAutomaticSilentRefresh();
    const hasValidAccessToken = this.oauthService.hasValidAccessToken();
    this.isAuthenticated$.next(hasValidAccessToken);
    if (hasValidAccessToken && this.sessionUser$.value === null) {
      this.loadUserProfile();
    }
  }

  public async logout(): Promise<void> {
    // ToDo: Left here for reference
    // await this.oauthService.revokeTokenAndLogout().then(() => {
    //   this.sessionUser$.complete();
    //   this.resetUser();
    //   this.unsetUserMetrics();
    // });
    // ----- Azure B2C -----
    this.oauthService.logOut();
  }

  public resetUser(): void {
    this.sessionUser$.next(null);
  }

  public stopSessionChecks(): void {
    this.oauthService.sessionChecksEnabled = false;
  }

  private setUserForTracking(user: FsxContact) {
    this.applicationInsightsService.setUserId(user.id ?? '');
  }

  private unsetUserMetrics() {
    this.applicationInsightsService.clearUserId();
  }

  private setSessionUserFromAccessToken(
    userProfileFromIdApi?: JwtUser
  ): Observable<hasSessionUserBeenSet> {
    const sessionUser: JwtUser | undefined = userProfileFromIdApi;
    // const idClaims = this.oauthService.getIdentityClaims();
    if (!sessionUser) {
      return of(false);
    }
    const contact: FsxContact = new FsxContact({
      id: sessionUser.sub,
      emails: [
        {
          address: sessionUser.email,
          caption: '',
          category: {
            caption: '',
            commonCategory: EmailCommonCategory.None,
            name: '',
          },
        },
      ],
      actor: {
        name: {
          fullName: sessionUser.name,
          givenName: sessionUser.given_name,
          middleName: sessionUser.middle_name,
          surName: sessionUser.family_name,
        },
      },
      organization: {
        primaryContact: {
          id: sessionUser.fsx_organization_id,
          caption: '',
          contactType: ContactType.Organization,
        },
        title: sessionUser.fsx_organization,
        caption: '',
      },
      type: sessionUser.fsx_user_type,
    });

    this.setUserForTracking(contact);
    this.sessionUser$.next(contact);
    return of(true);
  }

  private navigateToLoginPage(): void {
    // this.window.sessionStorage.setItem('current_uri', this.router.url);
    this.envConfig
      .pipe(
        // tap((envConfig) =>
        //   this.window.open(
        //     authCodeFlowConfig(envConfig.IdentityServer.BaseURL).loginUrl,
        //     '_self'
        //   )
        // ),
        take(1)
      )
      .subscribe();
  }

  private setSessionStorageListener(): void {
    // this.window.addEventListener('storage', (event) => {
    //   // console.warn('check session storage for access key');
    //   if (event.key !== 'access_token' && event.key !== null) {
    //     return;
    //   }
    //   // console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
    //   if (!this.oauthService.hasValidAccessToken()) {
    //     // console.warn('access token invalid - directing to login page');
    //     this.navigateToLoginPage();
    //   }
    // });
  }
  private setOAuthServiceEventListener(): void {
    this.oauthService.events
      .pipe(
        map((event: OAuthEvent) => {
          // console.warn('oauth event:\n', event);
          const hasValidAccessToken = this.oauthService.hasValidAccessToken();
          this.isAuthenticated$.next(hasValidAccessToken);
          if (
            ['token_received'].includes(event.type) &&
            this.sessionUser$.value === null
          ) {
            this.loadUserProfile();
          } else if (event.type === 'user_profile_loaded') {
            this.callUsersMeAfterUserLogsIn();
          } else if (
            ['session_terminated', 'session_error'].includes(event.type)
          ) {
            return this.navigateToLoginPage();
          } else if (event.type === 'token_expires') {
            this.oauthService.silentRefresh();
          }
        })
      )
      .subscribe();
  }

  /**
   * The /users/me API endpoint has been revised so that instead of just returning the user's
   * ContactSummary information it will also send a request for the user to be refreshed based
   * on the main user database, JCMS. (This means, for example, their emails, BAR number, etc.
   * will be updated to match the information in our main FSX product.)
   *
   * This refresh is the only way that a TenantOrganization can be created in the FSLA database,
   * so we need it to happen after a user logs in and before they attempt to set an Authorizer
   * on a filing.
   */
  private callUsersMeAfterUserLogsIn(): void {
    this.userApiService
      .getSelf()
      .pipe(
        take(1),
        tap((contactSummary: ContactSummary) => {
          this.userDataService.setContactSummary(contactSummary);
        })
      )
      .subscribe();
  }

  private loadUserProfile(): void {
    const userProfile: Promise<{ info: JwtUser }> =
      this.oauthService.loadUserProfile() as Promise<{ info: JwtUser }>;

    from(userProfile)
      .pipe(
        switchMap(({ info }: { info: JwtUser }) => {
          return this.setSessionUserFromAccessToken(info);
        }),
        take(1)
      )
      .subscribe();
  }
}
