import {
  AccessCommonCategory,
  AddressCommonCategory,
  CaseRequestViewModel,
  DocumentCommonCategory,
  EmailCommonCategory,
  FilingProfile,
  ParticipantCommonCategory,
  PhoneCommonCategory,
  ProfileLists,
  RequestDocumentViewModel,
} from '../../../types';

export const initialCaseRequest1: CaseRequestViewModel = {
  cases: [
    {
      caseId: '11111111-1111-1111-1111-111111111111',
      title: '11111',
      caption: '11111',
      efmKey: null,
      amountInControversy: null,
      isComplexCase: false,
      incidentAddress: null,
      additionalFieldValues: null,
    },
  ],
  parties: [],
  participants: [],
  documents: [],
  noteToClerk: '',
};

export const minimumFilingProfile: FilingProfile = {
  courtId: '32c7d9f3-9cd4-438c-80e7-69b66af5cbe6',
  id: '3532e015-fc2f-4d2a-937e-e28c618d5313',
  caption: 'Minimal Filing Profile',
  timeZone: '',
  requiresAttestationForCaseAccess: false,
  attestationStatementForCaseAccess: null,
  dailyFilingCutoff: '',
  allowsProposingCaseName: true,
  originalPetition: {
    allowFiling: true,
    allowFilingBySelfRepresentedUsers: false,
    allowService: true,
    authorizerVerbiage: 'Authorizer verbiage',
    allowCaseCreation: true,
    allowReferencingExistingCase: false,
    allowMulticase: false,
    allowCourtesyCopies: true,
    allowNoteToClerk: false,
    allowAddingPartiesToCase: true,
    allowAddingAttorneysToExistingParties: false,
    totalFileSizeMaxBytes: 20000000,
    description: 'Minimal Filing Profile',
    caseInstruction: null,
    case: {
      amountInControversy: null,
      incidentAddress: null,
      description: 'Minimal Filing Profile Case',
      additionalFields: null,
    },
    participantInstruction: null,
    participant: [
      {
        participantCategory: {
          commonCategory: ParticipantCommonCategory.InitiatingParty,
          sequence: 0,
          name: 'plain',
          caption: 'Plaintiff',
          efmKey: 'PLAIN',
        },
        minRequired: 1,
        maxAllowed: 3,
        allowPerson: true,
        allowOrganization: true,
        subCategory: null,
        person: {
          personalName: {
            prefix: null,
            givenName: null,
            middleName: null,
            surName: null,
            suffix: null,
            fullName: {
              required: true,
              minLength: 1,
              maxLength: 100,
              characterProfileName: 'default',
              readOnly: false,
              visible: true,
            },
          },
          dateOfDeath: null,
          dateOfBirth: null,
        },
        organization: {
          title: {
            required: true,
            minLength: 1,
            maxLength: 100,
            characterProfileName: 'default',
            readOnly: false,
            visible: true,
          },
        },
        identification: null,
        address: {
          minRequired: 0,
          maxAllowed: 10,
          addressProfileName: '', // null,
          country: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AdditionalList,
              additionalListName: 'countries',
            },
            defaultValue: 'US',
            selectionDependentFields: [], //null,
            readOnly: true,
            visible: false,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AddressCategories,
              additionalListName: '', //null
            },
            defaultValue: null!!,
            selectionDependentFields: [], //null,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        phone: {
          minRequired: 0,
          maxAllowed: 3,
          fullNumber: {
            required: true,
            minLength: 10,
            maxLength: 15,
            characterProfileName: 'phone',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.PhoneCategories,
              additionalListName: '', //null
            },
            defaultValue: null!,
            selectionDependentFields: [], //null,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        email: {
          minRequired: 0,
          maxAllowed: 10,
          address: {
            required: true,
            minLength: 1,
            maxLength: 150,
            characterProfileName: 'email',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.EmailCategories,
              additionalListName: '', //null
            },
            defaultValue: null!,
            selectionDependentFields: null!!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        alias: null,
        representation: {
          minRequired: 0,
          maxAllowed: 3,
          description: null,
          additionalFields: null,
        },
        parentOrganizationCaption: null,
        description: null,
        additionalFields: null,
      },
      {
        participantCategory: {
          commonCategory: ParticipantCommonCategory.AdditionalParty,
          sequence: 1,
          name: 'def',
          caption: 'Defendant',
          efmKey: 'DEF',
        },
        minRequired: 1,
        maxAllowed: 4,
        allowPerson: true,
        allowOrganization: false,
        subCategory: null,
        person: {
          personalName: {
            prefix: null,
            givenName: null,
            middleName: null,
            surName: null,
            suffix: null,
            fullName: {
              required: true,
              minLength: 1,
              maxLength: 100,
              characterProfileName: 'default',
              readOnly: false,
              visible: true,
            },
          },
          dateOfDeath: null,
          dateOfBirth: null,
        },
        organization: {
          title: {
            required: true,
            minLength: 1,
            maxLength: 100,
            characterProfileName: 'default',
            readOnly: false,
            visible: true,
          },
        },
        identification: null,
        address: {
          minRequired: 0,
          maxAllowed: 10,
          addressProfileName: null!!,
          country: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AdditionalList,
              additionalListName: 'countries',
            },
            defaultValue: 'US',
            selectionDependentFields: null!!,
            readOnly: true,
            visible: false,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AddressCategories,
              additionalListName: null!!,
            },
            defaultValue: null!,
            selectionDependentFields: null!!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        phone: {
          minRequired: 0,
          maxAllowed: 3,
          fullNumber: {
            required: true,
            minLength: 10,
            maxLength: 15,
            characterProfileName: 'phone',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.PhoneCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null!,
        },
        email: {
          minRequired: 0,
          maxAllowed: 10,
          address: {
            required: true,
            minLength: 1,
            maxLength: 150,
            characterProfileName: 'email',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.EmailCategories,
              additionalListName: null!!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        alias: null,
        representation: {
          minRequired: 0,
          maxAllowed: 3,
          description: null,
          additionalFields: null,
        },
        parentOrganizationCaption: null,
        description: null,
        additionalFields: null,
      },
      {
        participantCategory: {
          commonCategory: ParticipantCommonCategory.Attorney,
          sequence: 2,
          name: 'att',
          caption: 'Attorney',
          efmKey: 'ATT',
        },
        minRequired: 0,
        maxAllowed: 2,
        allowPerson: true,
        allowOrganization: false,
        subCategory: null,
        person: {
          personalName: {
            prefix: null,
            givenName: null,
            middleName: null,
            surName: null,
            suffix: null,
            fullName: {
              required: true,
              minLength: 1,
              maxLength: 100,
              characterProfileName: 'default',
              readOnly: false,
              visible: true,
            },
          },
          dateOfDeath: null,
          dateOfBirth: null,
        },
        organization: {
          title: {
            required: true,
            minLength: 1,
            maxLength: 100,
            characterProfileName: 'default',
            readOnly: false,
            visible: true,
          },
        },
        identification: null,
        address: {
          minRequired: 0,
          maxAllowed: 10,
          addressProfileName: null!!,
          country: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AdditionalList,
              additionalListName: 'countries',
            },
            defaultValue: 'US',
            selectionDependentFields: null!!,
            readOnly: true,
            visible: false,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AddressCategories,
              additionalListName: null!!,
            },
            defaultValue: null!,
            selectionDependentFields: null!!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        phone: {
          minRequired: 0,
          maxAllowed: 3,
          fullNumber: {
            required: true,
            minLength: 10,
            maxLength: 15,
            characterProfileName: 'phone',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.PhoneCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        email: {
          minRequired: 0,
          maxAllowed: 10,
          address: {
            required: true,
            minLength: 1,
            maxLength: 150,
            characterProfileName: 'email',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.EmailCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        alias: null,
        representation: null,
        parentOrganizationCaption: null,
        description: null,
        additionalFields: null,
      },
    ],
    documentInstruction: null,
    minLeadDocumentsRequired: 1,
    maxLeadDocumentsAllowed: 1,
    leadDocument: [
      {
        documentCategory: {
          commonCategory: DocumentCommonCategory.LeadDocument,
          sequence: 0,
          name: 'opfleaddoctype1',
          caption: 'OPF Lead Document Type 1',
          efmKey: 'opfleaddoctype1',
        },
        applicableWithExistingCaseReference: false,
        applicableWithoutExistingCaseReference: true,
        title: {
          required: true,
          minLength: 1,
          maxLength: 100,
          characterProfileName: 'default',
          readOnly: false,
          visible: true,
        },
        fileName: {
          required: true,
          minLength: 1,
          maxLength: 100,
          characterProfileName: 'filename',
          readOnly: false,
          visible: true,
        },
        filedBy: null,
        asTo: null,
        file: {
          fileTypeProfileName: 'pdfonlyfilingprofile',
          minRequired: 1,
          maxAllowed: 1,
          fileSizeMaxBytes: 5000000,
          fileNameMaxLength: 50,
          ignoredByTotalFileSize: false,
          sendOriginal: false,
          readOnly: false,
          visible: true,
          sendConverted: true, // ToDo: Just set this to the default value for now.
        },
        description: null,
        additionalFields: null,
      },
    ],
    supportingDocument: [],
    noteToClerk: null,
  },
  subsequent: {
    allowFiling: true,
    allowFilingBySelfRepresentedUsers: false,
    allowService: true,
    authorizerVerbiage: 'Authorizer verbiage',
    allowCaseCreation: true,
    allowReferencingExistingCase: false,
    allowMulticase: true,
    allowCourtesyCopies: true,
    allowNoteToClerk: false,
    allowAddingPartiesToCase: true,
    allowAddingAttorneysToExistingParties: false,
    totalFileSizeMaxBytes: 20000000,
    description: 'Minimal Filing Profile',
    caseInstruction: null,
    case: {
      amountInControversy: null,
      incidentAddress: null,
      description: 'Minimal Filing Profile Case',
      additionalFields: null,
    },
    participantInstruction: null,
    participant: [
      {
        participantCategory: {
          commonCategory: ParticipantCommonCategory.InitiatingParty,
          sequence: 0,
          name: 'plain',
          caption: 'Plaintiff',
          efmKey: 'PLAIN',
        },
        minRequired: 1,
        maxAllowed: 3,
        allowPerson: true,
        allowOrganization: true,
        subCategory: null,
        person: {
          personalName: {
            prefix: null,
            givenName: null,
            middleName: null,
            surName: null,
            suffix: null,
            fullName: {
              required: true,
              minLength: 1,
              maxLength: 100,
              characterProfileName: 'default',
              readOnly: false,
              visible: true,
            },
          },
          dateOfDeath: null,
          dateOfBirth: null,
        },
        organization: {
          title: {
            required: true,
            minLength: 1,
            maxLength: 100,
            characterProfileName: 'default',
            readOnly: false,
            visible: true,
          },
        },
        identification: null,
        address: {
          minRequired: 0,
          maxAllowed: 10,
          addressProfileName: null!,
          country: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AdditionalList,
              additionalListName: 'countries',
            },
            defaultValue: 'US',
            selectionDependentFields: null!,
            readOnly: true,
            visible: false,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AddressCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        phone: {
          minRequired: 0,
          maxAllowed: 3,
          fullNumber: {
            required: true,
            minLength: 10,
            maxLength: 15,
            characterProfileName: 'phone',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.PhoneCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        email: {
          minRequired: 0,
          maxAllowed: 10,
          address: {
            required: true,
            minLength: 1,
            maxLength: 150,
            characterProfileName: 'email',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.EmailCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        alias: null,
        representation: {
          minRequired: 0,
          maxAllowed: 3,
          description: null,
          additionalFields: null,
        },
        parentOrganizationCaption: null,
        description: null,
        additionalFields: null,
      },
      {
        participantCategory: {
          commonCategory: ParticipantCommonCategory.AdditionalParty,
          sequence: 1,
          name: 'def',
          caption: 'Defendant',
          efmKey: 'DEF',
        },
        minRequired: 1,
        maxAllowed: 4,
        allowPerson: true,
        allowOrganization: false,
        subCategory: null,
        person: {
          personalName: {
            prefix: null,
            givenName: null,
            middleName: null,
            surName: null,
            suffix: null,
            fullName: {
              required: true,
              minLength: 1,
              maxLength: 100,
              characterProfileName: 'default',
              readOnly: false,
              visible: true,
            },
          },
          dateOfDeath: null,
          dateOfBirth: null,
        },
        organization: {
          title: {
            required: true,
            minLength: 1,
            maxLength: 100,
            characterProfileName: 'default',
            readOnly: false,
            visible: true,
          },
        },
        identification: null,
        address: {
          minRequired: 0,
          maxAllowed: 10,
          addressProfileName: null!,
          country: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AdditionalList,
              additionalListName: 'countries',
            },
            defaultValue: 'US',
            selectionDependentFields: null!,
            readOnly: true,
            visible: false,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AddressCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        phone: {
          minRequired: 0,
          maxAllowed: 3,
          fullNumber: {
            required: true,
            minLength: 10,
            maxLength: 15,
            characterProfileName: 'phone',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.PhoneCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        email: {
          minRequired: 0,
          maxAllowed: 10,
          address: {
            required: true,
            minLength: 1,
            maxLength: 150,
            characterProfileName: 'email',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.EmailCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        alias: null,
        representation: {
          minRequired: 0,
          maxAllowed: 3,
          description: null,
          additionalFields: null,
        },
        parentOrganizationCaption: null,
        description: null,
        additionalFields: null,
      },
      {
        participantCategory: {
          commonCategory: ParticipantCommonCategory.Attorney,
          sequence: 2,
          name: 'att',
          caption: 'Attorney',
          efmKey: 'ATT',
        },
        minRequired: 0,
        maxAllowed: 2,
        allowPerson: true,
        allowOrganization: false,
        subCategory: null,
        person: {
          personalName: {
            prefix: null,
            givenName: null,
            middleName: null,
            surName: null,
            suffix: null,
            fullName: {
              required: true,
              minLength: 1,
              maxLength: 100,
              characterProfileName: 'default',
              readOnly: false,
              visible: true,
            },
          },
          dateOfDeath: null,
          dateOfBirth: null,
        },
        organization: {
          title: {
            required: true,
            minLength: 1,
            maxLength: 100,
            characterProfileName: 'default',
            readOnly: false,
            visible: true,
          },
        },
        identification: null,
        address: {
          minRequired: 0,
          maxAllowed: 10,
          addressProfileName: null!,
          country: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AdditionalList,
              additionalListName: 'countries',
            },
            defaultValue: 'US',
            selectionDependentFields: null!,
            readOnly: true,
            visible: false,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.AddressCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        phone: {
          minRequired: 0,
          maxAllowed: 3,
          fullNumber: {
            required: true,
            minLength: 10,
            maxLength: 15,
            characterProfileName: 'phone',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.PhoneCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        email: {
          minRequired: 0,
          maxAllowed: 10,
          address: {
            required: true,
            minLength: 1,
            maxLength: 150,
            characterProfileName: 'email',
            readOnly: false,
            visible: true,
          },
          category: {
            minRequired: 1,
            maxAllowed: 1,
            listReference: {
              list: ProfileLists.EmailCategories,
              additionalListName: null!,
            },
            defaultValue: null!,
            selectionDependentFields: null!,
            readOnly: false,
            visible: true,
          },
          description: null,
          additionalFields: null,
        },
        alias: null,
        representation: null,
        parentOrganizationCaption: null,
        description: null,
        additionalFields: null,
      },
    ],
    documentInstruction: null,
    minLeadDocumentsRequired: 1,
    maxLeadDocumentsAllowed: 1,
    leadDocument: [
      {
        documentCategory: {
          commonCategory: DocumentCommonCategory.LeadDocument,
          sequence: 0,
          name: 'opfleaddoctype1',
          caption: 'OPF Lead Document Type 1',
          efmKey: 'opfleaddoctype1',
        },
        applicableWithExistingCaseReference: false,
        applicableWithoutExistingCaseReference: true,
        title: {
          required: true,
          minLength: 1,
          maxLength: 100,
          characterProfileName: 'default',
          readOnly: false,
          visible: true,
        },
        fileName: {
          required: true,
          minLength: 1,
          maxLength: 100,
          characterProfileName: 'filename',
          readOnly: false,
          visible: true,
        },
        filedBy: null,
        asTo: null,
        file: {
          fileTypeProfileName: 'pdfonlyfilingprofile',
          minRequired: 1,
          maxAllowed: 1,
          fileSizeMaxBytes: 5000000,
          fileNameMaxLength: 50,
          ignoredByTotalFileSize: false,
          sendOriginal: false,
          readOnly: false,
          visible: true,
          sendConverted: true, // ToDo: Just set this to the default value for now (true).
        },
        description: null,
        additionalFields: null,
      },
    ],
    supportingDocument: [],
    noteToClerk: null,
  },
  appellate: null,
  documentAccessCategories: [
    {
      commonCategory: AccessCommonCategory.Public,
      sequence: 0,
      name: 'public',
      caption: 'Public',
      efmKey: 'access-public',
    },
  ],
  identificationCategories: [],
  alias: [],
  addressCategories: [
    {
      commonCategory: AddressCommonCategory.Other,
      sequence: 0,
      name: 'general',
      caption: 'General',
      efmKey: 'address-general',
    },
  ],
  phoneCategories: [
    {
      commonCategory: PhoneCommonCategory.Other,
      sequence: 0,
      name: 'general',
      caption: 'General',
      efmKey: 'phone-general',
    },
  ],
  emailCategories: [
    {
      commonCategory: EmailCommonCategory.Other,
      sequence: 0,
      name: 'general',
      caption: 'General',
      efmKey: 'email-general',
    },
  ],
  aliasCategories: [],
  fileTypes: [
    {
      contentType: 'application/pdf',
      caption: '*.pdf',
      filenameExtensions: ['pdf'],
    },
  ],
  fileTypeProfiles: [
    {
      name: 'pdfonlyfilingprofile',
      allowedContentTypes: ['application/pdf'],
    },
  ],
  characterProfiles: [
    {
      name: 'default',
      characterSet:
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 -',
    },
    {
      name: 'filename',
      characterSet:
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_.',
    },
    {
      name: 'phone',
      characterSet: '0123456789-.x ',
    },
    {
      name: 'email',
      characterSet:
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-.@',
    },
  ],
  defaultCharacterProfileName: 'default',
  addressProfiles: [
    {
      name: 'address-profile',
      spec: {
        addressLine1Caption: 'AddressLine1',
        addressLine1: {
          required: true,
          minLength: 1,
          maxLength: 100,
          characterProfileName: 'default',
          readOnly: false,
          visible: true,
        },
        addressLine2Caption: null,
        addressLine2: null,
        localityCaption: 'Locality',
        locality: {
          required: true,
          minLength: 1,
          maxLength: 100,
          characterProfileName: 'default',
          readOnly: false,
          visible: true,
        },
        administrativeAreaCaption: 'AdministrativeArea',
        administrativeArea: null,
        postalCodeCaption: 'PostalCode',
        postalCode: {
          required: true,
          minLength: 1,
          maxLength: 100,
          characterProfileName: 'default',
          readOnly: false,
          visible: true,
        },
      },
      countrySpecs: [],
    },
  ],
  defaultAddressProfileName: 'address-profile',
  additionalLists: [
    {
      name: 'defendant-only',
      items: [
        {
          name: 'def',
          caption: 'Defendant',
          efmKey: 'DEF',
        },
      ],
    },
    {
      name: 'plaintiff-only',
      items: [
        {
          name: 'plain',
          caption: 'Plaintiff',
          efmKey: 'PLAIN',
        },
      ],
    },
    {
      name: 'countries',
      items: [
        {
          name: 'US',
          caption: 'US',
          efmKey: '',
        },
      ],
    },
  ],
  efmKey: 'test_minimal',
  classification: [
    {
      label: 'Case Class',
      caption: 'Edge Case Test Profiles',
      key: 'edge-cases',
    },
    {
      label: 'Case Type',
      caption: 'Minimal Filing Profile',
      key: '',
    },
  ],
};

export const sample1PdfLeadDocument: RequestDocumentViewModel = {
  cases: [
    {
      caseId: '00000000-0000-0000-0000-000000000000',
      filedBy: [],
      filedAsTo: [],
      linkedDocuments: [],
      additionalFieldValues: [],
      isNew: true,
    },
  ],
  id: '3c2a432d-02f9-49b6-a7f0-ddd5afe8f55c',
  name: 'Sample-01.pdf',
  title: 'Sample-01',
  fileName: 'Sample-01.pdf',
  isLeadDocument: true,
  isNew: false,
  category: {
    commonCategory: DocumentCommonCategory.LeadDocument,
    // sequence: 0,
    name: 'opfleaddoctype1',
    caption: 'OPF Lead Document Type 1',
    // efmKey: 'opfleaddoctype1'
  },
  accessCategoryName: 'public',
};
