import { Observable } from 'rxjs';
import {
  CASE_PARTIES_ROUTE,
  DOCUMENTS_ROUTE,
  REVIEW_ROUTE,
  OLD_ROUTE,
} from '../ui-state';

export interface TransactionTabBase {
  abbrvTitle?: string;
  courtId?: string;
  filingId: string;
  profileId?: string;
  title?: string;
  path?: string;
}

export interface TransactionTab extends TransactionTabBase {
  title: string;
  path: string;
}

export interface TransactionTabsMetadata {
  tabs: TransactionTab[];
  activeTabIdx: number;
}

export interface TransactionSubTabsInfo {
  filingStepNumber: number;
  isSubmitStep: boolean;
  name: OLD_ROUTE;
  nextPath?: OLD_ROUTE;
  previousPath?: OLD_ROUTE;
  title: string;
  validationKey:
    | 'documentValidationErrors'
    | 'partyValidationErrors'
    | 'reviewValidationErrors';
}

export interface TransactionSubTabMetadata {
  activeMainTabInfo: TransactionTab;
  activeSubTab: OLD_ROUTE;
  tabs: TransactionSubTab[];
}
export interface TransactionSubTab {
  route: OLD_ROUTE;
  title: string;
  isActive: boolean;
}

export interface ITransactionTabsService {
  activeTabIdx: number;
  currentTab: Observable<TransactionTab>;
  subTabs: Observable<TransactionSubTabMetadata>;
  tabs: Observable<TransactionTabsMetadata>;
  tabsList: TransactionTab[];

  addNewTab(filingTab: TransactionTabBase): void;
  removeTab(tabIdx: number): void;
  setCurrentTab(filingTabIdx: number): void;
  updateTabPath(updatedFilingTab: TransactionTab): void;
  updateActiveSubTab(activeSubTab: OLD_ROUTE): void;
}

export const TRANSACTION_SUB_TABS: TransactionSubTab[] = [
  {
    route: CASE_PARTIES_ROUTE,
    title: 'Parties',
    isActive: false,
  },
  {
    route: DOCUMENTS_ROUTE,
    title: 'Documents',
    isActive: true,
  },
  {
    route: REVIEW_ROUTE,
    title: 'Review & Submit',
    isActive: false,
  },
];

export const casePartiesTabRouteData: TransactionSubTabsInfo = {
  filingStepNumber: 1,
  isSubmitStep: false,
  name: OLD_ROUTE.CASE_PARTIES,
  nextPath: OLD_ROUTE.DOCUMENTS,
  previousPath: undefined,
  title: 'Parties',
  validationKey: 'partyValidationErrors',
};

export const documentsTabRouteData: TransactionSubTabsInfo = {
  filingStepNumber: 2,
  isSubmitStep: false,
  name: OLD_ROUTE.DOCUMENTS,
  nextPath: OLD_ROUTE.REVIEW,
  previousPath: OLD_ROUTE.CASE_PARTIES,
  title: 'Documents',
  validationKey: 'documentValidationErrors',
};

export const reviewAndSubmitTabRouteData: TransactionSubTabsInfo = {
  filingStepNumber: 3,
  isSubmitStep: true,
  name: OLD_ROUTE.REVIEW,
  nextPath: undefined,
  previousPath: OLD_ROUTE.DOCUMENTS,
  title: 'Review & Submit',
  validationKey: 'reviewValidationErrors',
};
