import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { Observable, combineLatest, map } from 'rxjs';
import {
  FsxParticipantDataService,
  IParticipantDataService,
} from './participant-data.service';
import { FsxPartyDataService, IPartyDataService } from './party-data.service';

/**
 * An interface to combine the related CaseParty and RequestParticipant objects
 * into a single type along with the row number on which they reside in the grid.
 */
export interface PartyAndParticipant {
  /**
   * A CaseParty object (related to the RequestParticipant object below)
   */
  party: CasePartyViewModel;

  /**
   * A RequestPartipant object (related to the CaseParty object above)
   */
  participant: RequestParticipantViewModel;

  /**
   * The index of the party in the caseRequest.parties array
   */
  partyIndex: number;
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IPartyAndParticipantDataService to use at runtime.
 */
export const FsxPartyAndParticipantDataService =
  new InjectionToken<IPartyAndParticipantDataService>(
    'FsxPartyAndParticipantDataService'
  );

/**
 * A blueprint for a UI service for mapping CaseParty objects to RequestParticipant objects.
 */
export interface IPartyAndParticipantDataService {
  /**
   * A public member, which maps CaseParty objects to RequestParticipant objects.
   */
  partiesWithParticipants$: Observable<PartyAndParticipant[]>;
}

/**
 * A concrete implementation of a state service for storing an array of CaseParty objects.
 */
@Injectable()
export class PartyAndParticipantDataService
  implements IPartyAndParticipantDataService
{
  /**
   * A public member, which maps CaseParty objects to RequestParticipant objects.
   * Derived from the partyDataService and participantDataService.
   */
  partiesWithParticipants$ = combineLatest([
    this.partyDataService.parties$,
    this.participantDataService.participants$,
  ]).pipe(
    map(
      ([parties, participants]: [
        CasePartyViewModel[],
        RequestParticipantViewModel[]
      ]) => {
        // Derive an array of PartyAndParticipant objects from the parties and participants arrays.
        const partiesWithParticipants: PartyAndParticipant[] = parties.reduce(
          (accPartiesWithParticipants, curParty, index) => {
            // Lookup the matching RequestParticipant object from the ParticipantDataService.
            const participant: RequestParticipantViewModel | undefined =
              participants.find((p: RequestParticipantViewModel) => {
                return p.name === curParty.participantName;
              });

            // Only push to the array if we have the matching RequestParticipant object.
            if (participant) {
              // Combine the CaseParty and RequestParticipant into a single object.
              const partyAndParticipant: PartyAndParticipant = {
                party: curParty,
                participant,
                partyIndex: index,
              };
              // Add the PartyAndParticipant object to the accumulated array of PartyAndParticipant objects.
              accPartiesWithParticipants.push(partyAndParticipant);
            }

            return accPartiesWithParticipants;
          },
          [] as PartyAndParticipant[]
        );

        return partiesWithParticipants;
      }
    )
  );

  /**
   *
   * @param partyDataService Where we get the CaseParty objects from.
   * @param participantDataService Where we get the RequestParticipant objects from.
   */
  constructor(
    @Inject(FsxPartyDataService)
    private readonly partyDataService: IPartyDataService,
    @Inject(FsxParticipantDataService)
    private readonly participantDataService: IParticipantDataService
  ) {}
}
