<fsx-panel
  (closePanelEvent)="closePanelEventHandler()"
  (defaultPanelEvent)="defaultPanelEventHandler()"
  [config]="panelConfig"
>
  <div header>
    <fsx-contact-form-panel-header [config]="headerConfig">
    </fsx-contact-form-panel-header>
  </div>
  <div content>
    <fsx-contact-form
      [contactFormGroup]="contactFormGroup"
      [contactProfile]="data.contactProfile"
      [formMode]="data.formMode"
      [contact]="data.contact"
      [combinedFilingData]="
        (combinedFilingDataService.combinedFilingData$ | async)!
      "
      (contactTypeChanged)="contactTypeChangedHandler($event)"
      (isFormValidEvent)="isFormValidEventHandler($event)"
    ></fsx-contact-form>
  </div>
  <div footer>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
      <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
        <button class="secondary" (click)="onCancelButtonClicked()">
          Cancel
        </button>
        <button
          class="primary"
          [disabled]="canSaveForm === false"
          [ngClass]="{ disabled: canSaveForm === false }"
          (click)="saveButtonClickHandler()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</fsx-panel>
