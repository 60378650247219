import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  AdditionalFieldSpec,
  CombinedFilingData,
  DocumentCategory,
  DocumentSpec,
  FilingModeSpec,
} from '@fsx/fsx-shared';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import { AdditionalFieldNamePlusDocumentCategory } from './additional-field-value-lookup.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IAdditionalFieldLookupService to use at runtime.
 */
export const FsxAdditionalFieldSpecLookupService =
  new InjectionToken<IAdditionalFieldSpecLookupService>(
    'FsxAdditionalFieldSpecLookupService'
  );

/**
 * A blueprint for a ui service, which looks up AdditionalFieldSpec objects on the
 * current FilingNModeSpec.
 */
export interface IAdditionalFieldSpecLookupService {
  /**
   * a function for looking up AdditionalFieldSpec objects on the current FilingModeSpec.
   *
   * @param additionalFieldNamePlusDocumentCategory An object containing the name (id) of the additional field(s) to lookup
   * along withe the document category, which is also needed to narrow down on the correct DocumentSpec.
   *
   * @returns A single AdditionalFieldSpec object for the given additionalFieldName.
   */
  getAdditionalFieldSpec(
    additionalFieldNamePlusDocumentCategory: AdditionalFieldNamePlusDocumentCategory
  ): Observable<AdditionalFieldSpec>;

  /**
   * A function for looking up the AdditionalFieldSpec captions on the current FilingModeSpec.
   *
   * @param additionalFieldName The name (id) of the additional field(s) to lookup. These
   * name are not necessarily unique so the results are return in an array.
   *
   * @returns An caption string for the given additionalFieldName.
   */
  getAdditionalFieldSpecCaption(
    additionalFieldNamePlusDocumentCategory: AdditionalFieldNamePlusDocumentCategory
  ): Observable<string>;
}

/**
 * A concrete implementation for a ui service, which looks up AdditionalFieldSpec
 * objects on the current FilingNModeSpec.
 */
@Injectable()
export class AdditionalFieldSpecLookupService
  implements IAdditionalFieldSpecLookupService
{
  /**
   * The current FilingModeSpec as taken from the CombinedFilingData.
   */
  private modeSpec$: Observable<FilingModeSpec> =
    this.combinedFilingDataService.combinedFilingData$.pipe(
      map((combinedFilingData: CombinedFilingData) => {
        return combinedFilingData.modeSpec;
      })
    );

  /**
   * An array of all of the lead DocumentSpec objects.
   */
  private leadDocumentSpecs$: Observable<DocumentSpec[]> = this.modeSpec$.pipe(
    map((modeSpec: FilingModeSpec) => {
      return modeSpec.leadDocument;
    })
  );

  /**
   * An array of all of the supporting DocumentSpec objects.
   */
  private supportingDocumentSpecs$: Observable<DocumentSpec[]> =
    this.modeSpec$.pipe(
      map((modeSpec: FilingModeSpec) => {
        return modeSpec.supportingDocument;
      })
    );

  /**
   * A single array containing all documentSpecs (from both lead and supporting).
   */
  private allDocumentSpecs$: Observable<DocumentSpec[]> = combineLatest([
    this.leadDocumentSpecs$,
    this.supportingDocumentSpecs$,
  ]).pipe(
    map(
      ([leadDocumentSpecs, supportingDocumentSpecs]: [
        DocumentSpec[],
        DocumentSpec[]
      ]) => {
        return [...leadDocumentSpecs, ...supportingDocumentSpecs];
      }
    )
  );

  /**
   * Derive a flat array of all of the documentSpec's AdditionalFieldSpecs.
   */
  private additionalFields$: Observable<AdditionalFieldSpec[]> =
    this.allDocumentSpecs$.pipe(
      map((documentSpecs: DocumentSpec[]) => {
        return documentSpecs.flatMap((docSpec) => {
          const additionalFields = docSpec.additionalFields || [];
          return additionalFields;
        });
      })
    );

  /**
   *
   * @param combinedFilingDataService The service containing the combinedFilingData.modeSpec which we lookup everything from.
   */
  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService
  ) {}

  /**
   * a function for looking up AdditionalFieldSpec objects on the current FilingModeSpec.
   *
   * @param additionalFieldNamePlusDocumentCategory An object containing the name (id) of the additional field(s) to lookup
   * along withe the document category, which is also needed to narrow down on the correct DocumentSpec.
   *
   * @returns A single AdditionalFieldSpec object for the given additionalFieldName.
   */
  getAdditionalFieldSpec(
    additionalFieldNamePlusDocumentCategory: AdditionalFieldNamePlusDocumentCategory
  ): Observable<AdditionalFieldSpec> {
    return this.getDocumentSpec(
      additionalFieldNamePlusDocumentCategory.category!
    ).pipe(
      take(1), // Ensures that the stream completes with the values that it returns.
      map((documentSpec: DocumentSpec) => {
        // Find the AdditionalFieldSpec object with the DocumentSpec object.
        const additionalFieldSpecs = documentSpec?.additionalFields || [];
        const additionalFieledSpec: AdditionalFieldSpec | undefined =
          additionalFieldSpecs.find((spec) => {
            return (
              spec.name ===
              additionalFieldNamePlusDocumentCategory.additionalFieldName
            );
          });

        if (!additionalFieledSpec) {
          console.warn('AdditionalFieldSpec object not found');
        }

        return additionalFieledSpec!;
      })
    );
  }

  /**
   * A function for looking up the AdditionalFieldSpec captions on the current FilingModeSpec.
   *
   * @param additionalFieldName The name (id) of the additional field(s) to lookup. These
   * name are not necessarily unique so the results are return in an array.
   *
   * @returns An caption string for the given additionalFieldName.
   */
  getAdditionalFieldSpecCaption(
    additionalFieldNamePlusDocumentCategory: AdditionalFieldNamePlusDocumentCategory
  ): Observable<string> {
    return this.getAdditionalFieldSpec(
      additionalFieldNamePlusDocumentCategory
    ).pipe(
      filter((additionalFieldSpec: AdditionalFieldSpec) => {
        return !!additionalFieldSpec;
      }),
      map((additionalFieldSpec: AdditionalFieldSpec) => {
        return additionalFieldSpec.caption;
      })
    );
  }

  private getDocumentSpec(
    documentCategory: DocumentCategory
  ): Observable<DocumentSpec> {
    return this.allDocumentSpecs$.pipe(
      map((allDocumentSpecs: DocumentSpec[]) => {
        // Find the DocumentSpec object for the given document category.
        const documentSpec: DocumentSpec | undefined = allDocumentSpecs.find(
          (docSpec: DocumentSpec) => {
            return docSpec.documentCategory.name === documentCategory.name;
          }
        );

        if (!documentSpec) {
          console.warn('DocumentSpec object not found');
        }

        return documentSpec!;
      })
    );
  }
}
