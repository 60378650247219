import {
  AddressCommonCategory,
  ContactType,
  EmailCommonCategory,
  PhoneCommonCategory,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';

export const participant1: RequestParticipantViewModel = {
  linkedContact: {
    id: 'c3e530c1-f4d4-9e75-4877-31033ebcef52',
    caption: 'Jimmie Rogahn',
    contactType: ContactType.Person,
    clientNameText: null,
  },
  name: 'b1c6ceec-2526-4481-8790-cb83149db08a',
  parentOrganizationCaption: null,
  aliases: [],
  caption: 'Jimmie Rogahn',
  contactType: ContactType.Person,
  person: {
    personalName: {
      givenName: 'Jimmie',
      middleName: '',
      surName: 'Rogahn',
      prefix: null,
      suffix: null,
      fullName: 'Jimmie Rogahn',
      isValid: true,
    },
    dateOfDeath: null,
    dateOfBirth: null,
  },
  addresses: [
    {
      category: {
        name: 'home-address',
        caption: 'Home Address',
        commonCategory: AddressCommonCategory.Home,
      },
      caption: '',
      addressLine1: '30549 Daphney Cliffs',
      addressLine2: '61826',
      locality: 'Erdmanstad',
      administrativeArea: 'AK',
      postalCode: '10365',
      postalCodeExtension: null,
      country: 'AF',
      isValid: true,
    },
    {
      category: {
        name: 'home-address',
        caption: 'Home Address',
        commonCategory: AddressCommonCategory.Home,
      },
      caption: '',
      addressLine1: '768 Jerad Squares',
      addressLine2: '0067',
      locality: 'Wuckertchester',
      administrativeArea: 'AZ',
      postalCode: '36482',
      postalCodeExtension: null,
      country: 'AL',
      isValid: true,
    },
  ],
  emails: [
    {
      category: {
        name: 'personal-email',
        caption: 'Personal Email',
        commonCategory: EmailCommonCategory.Personal,
      },
      caption: '',
      address: 'Clint.Boyle@hotmail.com.dontemail',
      isValid: true,
    },
  ],
  identifications: [],
  phones: [
    {
      category: {
        name: 'office-phone',
        caption: 'Office Phone',
        commonCategory: PhoneCommonCategory.Business,
      },
      caption: '',
      countryKey: null,
      fullNumber: '1807315724',
      suffix: null,
      isValid: true,
    },
    {
      category: {
        name: 'office-phone',
        caption: 'Office Phone',
        commonCategory: PhoneCommonCategory.Business,
      },
      caption: '',
      countryKey: null,
      fullNumber: '1661295878',
      suffix: null,
      isValid: true,
    },
  ],
};
