import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CombinedFilingData, RequestDocumentViewModel } from '@fsx/fsx-shared';
import { FilesUploadedFromAdditionalFieldEventParams } from '../document-form/document-form.component';
import {
  IDocumentFileUploadedEventParams,
  ISupportingFilesUploadedEventParams,
  RemoveDocumentEventParams,
} from '../documents-grid-item/documents-grid-item.component';
import { DocumentsGridRow } from './documents-grid.model';
import {
  FsxUpdateDocumentOrchestrationService,
  IUpdateDocumentOrchestrationService,
} from '../services/update-document-orchestration.service';

@Component({
  selector: 'fsx-documents-grid',
  templateUrl: './documents-grid.component.html',
  styleUrls: ['./documents-grid.component.scss'],
})
export class DocumentsGridComponent implements OnChanges {
  @Input() documentsGridRows: DocumentsGridRow[] = [];
  @Input() combinedFilingData!: CombinedFilingData;
  @Output() removeDocumentEvent = new EventEmitter<RemoveDocumentEventParams>();
  @Output() cancelUploadEvent = new EventEmitter<RequestDocumentViewModel>();
  @Output() toggleExpandRowEvent = new EventEmitter<DocumentsGridRow>();
  @Output() reorderDocumentsEvent = new EventEmitter<DocumentsGridRow[]>();
  @Output() supportingFilesUploadedEvent =
    new EventEmitter<ISupportingFilesUploadedEventParams>();
  @Output() documentFileUploadedEvent =
    new EventEmitter<IDocumentFileUploadedEventParams>();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  requestDocuments: RequestDocumentViewModel[] = [];
  errorCount!: number;
  validationFilteredClass: string = '';

  constructor(
    @Inject(FsxUpdateDocumentOrchestrationService)
    private readonly updateDocumentOrchestrationService: IUpdateDocumentOrchestrationService
  ) {}

  ngOnChanges(): void {
    this.requestDocuments = this.documentsGridRows.map(
      (dgr: DocumentsGridRow) => {
        return dgr.requestDocument;
      }
    );

    this.validate();
  }

  // Prevents the DocumentGridItemComponent from reinitialising on every data emission.
  // (Allowing the DocumentsFormComponent to to keep the focus)
  identify(_index: number, item: DocumentsGridRow) {
    return item.requestDocument.id;
  }

  removeDocumentEventHandler(params: RemoveDocumentEventParams) {
    this.removeDocumentEvent.emit(params);
  }

  toggleExpandRowEventHandler(documentsGridRow: DocumentsGridRow) {
    this.toggleExpandRowEvent.emit(documentsGridRow);
  }

  cancelUploadEventHandler(row: DocumentsGridRow) {
    this.cancelUploadEvent.emit(row.requestDocument);
  }

  documentFileUploadedEventHandler(params: IDocumentFileUploadedEventParams) {
    this.documentFileUploadedEvent.emit(params);
  }

  supportingFilesUploadedEventHandler(
    params: ISupportingFilesUploadedEventParams
  ): void {
    this.supportingFilesUploadedEvent.emit(params);
  }

  cdkDropListDroppedHandler(event: CdkDragDrop<DocumentsGridRow[]>) {
    const documentsGridRows: DocumentsGridRow[] = event.container.data;
    const indexMovedFrom: number = event.previousIndex;
    const indexMovedTo: number = event.currentIndex;
    moveItemInArray(documentsGridRows, indexMovedFrom, indexMovedTo);
    this.reorderDocumentsEvent.emit(this.documentsGridRows);

    if (indexMovedTo === 0) {
      const { requestDocument } = this.documentsGridRows[0];
      const partialRequestDocument: Partial<RequestDocumentViewModel> = {
        isLeadDocument: true,
      };

      this.updateDocumentOrchestrationService.updateDocument({
        requestDocument,
        partialRequestDocument,
      });
    }
  }

  private validate(): void {
    this.errorCount = this.requestDocuments.reduce(
      (acc, curr) => (curr.isValid === false ? acc + 1 : acc),
      0
    );
  }

  documentErrorChangedEvent(): void {
    this.validate();
  }

  validationFilterChanged(filteredClass: string) {
    this.validationFilteredClass = filteredClass;
  }

  public filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedFromAdditionalFieldEventParams
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }
}
