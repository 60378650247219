import { Injectable, InjectionToken } from '@angular/core';
import {
  ContactAliasViewModel,
  ContactPersonViewModel,
  getPersonFullName,
  NewContactViewModel,
  ParticipantSpec,
} from '@fsx/fsx-shared';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IPersonFormService to use at runtime.
 */
export const FsxPersonFormService = new InjectionToken<IPersonFormService>(
  'FsxPersonFormService'
);

export interface IPersonFormService {
  setPersonName(contact: NewContactViewModel | ContactAliasViewModel): void;

  getPersonNameFromFilingProfile(
    contactPerson: ContactPersonViewModel | null | undefined,
    participantSpec: ParticipantSpec
  ): ContactPersonViewModel;
}

@Injectable()
export class PersonFormService implements IPersonFormService {
  public setPersonName(
    contact: NewContactViewModel | ContactAliasViewModel
  ): void {
    if (
      contact.person?.personalName &&
      !contact.person?.personalName.givenName
    ) {
      contact.person.personalName.givenName = '';
    }
    if (contact.person?.personalName && !contact.person?.personalName.surName) {
      contact.person.personalName.surName = '';
    }
  }

  public getPersonNameFromFilingProfile(
    contactPerson: ContactPersonViewModel | null | undefined,
    participantSpec: ParticipantSpec
  ): ContactPersonViewModel {
    const newContactPerson: ContactPersonViewModel = {
      dateOfBirth: null,
      dateOfDeath: null,
      personalName: {
        prefix: participantSpec?.person?.personalName?.prefix
          ? contactPerson?.personalName.prefix
          : null,
        givenName: participantSpec?.person?.personalName?.givenName
          ? contactPerson?.personalName.givenName
          : null,
        middleName: participantSpec?.person?.personalName?.middleName
          ? contactPerson?.personalName.middleName
          : null,
        surName: participantSpec?.person?.personalName?.surName
          ? contactPerson?.personalName.surName
          : null,
        suffix: participantSpec?.person?.personalName?.suffix
          ? contactPerson?.personalName.suffix
          : null,
        fullName: contactPerson?.personalName.fullName?.length
          ? contactPerson?.personalName.fullName
          : getPersonFullName(contactPerson?.personalName),
      },
    } as unknown as ContactPersonViewModel;
    return newContactPerson;
  }
}
