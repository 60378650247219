<div class="filing-content" *ngIf="filing$ | async as filing">
  <div fxLayout="row">
    <div fxLayout="col" class="json-data-col">
      <pre>filing... <br /><br />{{ filing$ | async | json }}</pre>
    </div>
    <div fxLayout="col" class="json-data-col">
      <pre>filing profile... <br /><br />{{ filingProfile$ | async | json }}</pre>
    </div>
    <div fxLayout="col" class="json-data-col">
      <pre>case request... <br /><br />{{ caseRequest$ | async | json }}</pre>
    </div>
  </div>
</div>
